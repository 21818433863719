import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';
import { SharedService } from './core/data-factory/SharedService';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes,
            withRouterConfig({
               onSameUrlNavigation: 'reload'
            }),
            withPreloading(PreloadAllModules)
            
        ),
         [SharedService],
        // Material Date Adapter
        {
            provide : DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: 'D',
                },
                display: {
                    dateInput         : 'DDD',
                    monthYearLabel    : 'LLL yyyy',
                    dateA11yLabel     : 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay   : 0,
                services: mockApiServices,
            },
            fuse   : {
                layout : 'classy',
                scheme : 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme  : 'theme-default',
                themes : [
                    {
                        id  : 'theme-default',
                        name: 'Default',
                    },
                    {
                        id  : 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id  : 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id  : 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id  : 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id  : 'theme-amber',
                        name: 'Amber',
                    },
                    {
                        id  : 'theme-test',
                        name: 'Test',
                    }
                ],
            },
        }),
    ],
};

export const themeShades = {
    "--fuse-primary-50": "#eff5f4",
    "--fuse-primary-50-rgb": "239,245,244",
    "--fuse-primary-100": "#e1ecea",
    "--fuse-primary-100-rgb": "225,236,234",
    "--fuse-primary-200": "#c3d9d6",
    "--fuse-primary-200-rgb": "195,217,214",
    "--fuse-primary-300": "#a0c5c0",
    "--fuse-primary-300-rgb": "160,197,192",
    "--fuse-primary-400": "#71aea6",
    "--fuse-primary-400-rgb": "113,174,166",
    "--fuse-primary-500": "#0d9488",
    "--fuse-primary-500-rgb": "13,148,136",
    "--fuse-primary-600": "#0c867b",
    "--fuse-primary-600-rgb": "12,134,123",
    "--fuse-primary-700": "#0a776d",
    "--fuse-primary-700-rgb": "10,119,109",
    "--fuse-primary-800": "#09655d",
    "--fuse-primary-800-rgb": "9,101,93",
    "--fuse-primary-900": "#074f49",
    "--fuse-primary-900-rgb": "7,79,73",
    "--fuse-primary-950": "#042f2e",
    "--fuse-primary-950-rgb": "4,47,46",
    "--fuse-primary": "#0d9488",
    "--fuse-primary-rgb": "13,148,136",
    "--fuse-on-primary-50": "#074f49",
    "--fuse-on-primary-50-rgb": "7,79,73",
    "--fuse-on-primary-100": "#074f49",
    "--fuse-on-primary-100-rgb": "7,79,73",
    "--fuse-on-primary-200": "#074f49",
    "--fuse-on-primary-200-rgb": "7,79,73",
    "--fuse-on-primary-300": "#074f49",
    "--fuse-on-primary-300-rgb": "7,79,73",
    "--fuse-on-primary-400": "#074f49",
    "--fuse-on-primary-400-rgb": "7,79,73",
    "--fuse-on-primary-500": "#074f49",
    "--fuse-on-primary-500-rgb": "7,79,73",
    "--fuse-on-primary-600": "#074f49",
    "--fuse-on-primary-600-rgb": "7,79,73",
    "--fuse-on-primary-700": "#FFFFFF",
    "--fuse-on-primary-700-rgb": "255,255,255",
    "--fuse-on-primary-800": "#FFFFFF",
    "--fuse-on-primary-800-rgb": "255,255,255",
    "--fuse-on-primary-900": "#FFFFFF",
    "--fuse-on-primary-900-rgb": "255,255,255",
    "--fuse-on-primary-950": "#FFFFFF",
    "--fuse-on-primary-950-rgb": "255,255,255",
    "--fuse-on-primary": "#FFFFFF",
    "--fuse-on-primary-rgb": "255,255,255",
    "--fuse-accent-50": "#f2f3f4",
    "--fuse-accent-50-rgb": "242,243,244",
    "--fuse-accent-100": "#e7e8eb",
    "--fuse-accent-100-rgb": "231,232,235",
    "--fuse-accent-200": "#ced1d7",
    "--fuse-accent-200-rgb": "206,209,215",
    "--fuse-accent-300": "#b2b8c1",
    "--fuse-accent-300-rgb": "178,184,193",
    "--fuse-accent-400": "#909aa8",
    "--fuse-accent-400-rgb": "144,154,168",
    "--fuse-accent-500": "#64748b",
    "--fuse-accent-500-rgb": "100,116,139",
    "--fuse-accent-600": "#5b697e",
    "--fuse-accent-600-rgb": "91,105,126",
    "--fuse-accent-700": "#505d70",
    "--fuse-accent-700-rgb": "80,93,112",
    "--fuse-accent-800": "#444f5f",
    "--fuse-accent-800-rgb": "68,79,95",
    "--fuse-accent-900": "#363f4b",
    "--fuse-accent-900-rgb": "54,63,75",
    "--fuse-accent-950": "#64748b",
    "--fuse-accent-950-rgb": "100,116,139",
    "--fuse-accent": "#64748b",
    "--fuse-accent-rgb": "100,116,139",
    "--fuse-on-accent-50": "#363f4b",
    "--fuse-on-accent-50-rgb": "54,63,75",
    "--fuse-on-accent-100": "#363f4b",
    "--fuse-on-accent-100-rgb": "54,63,75",
    "--fuse-on-accent-200": "#363f4b",
    "--fuse-on-accent-200-rgb": "54,63,75",
    "--fuse-on-accent-300": "#363f4b",
    "--fuse-on-accent-300-rgb": "54,63,75",
    "--fuse-on-accent-400": "#363f4b",
    "--fuse-on-accent-400-rgb": "54,63,75",
    "--fuse-on-accent-500": "#FFFFFF",
    "--fuse-on-accent-500-rgb": "255,255,255",
    "--fuse-on-accent-600": "#FFFFFF",
    "--fuse-on-accent-600-rgb": "255,255,255",
    "--fuse-on-accent-700": "#FFFFFF",
    "--fuse-on-accent-700-rgb": "255,255,255",
    "--fuse-on-accent-800": "#FFFFFF",
    "--fuse-on-accent-800-rgb": "255,255,255",
    "--fuse-on-accent-900": "#FFFFFF",
    "--fuse-on-accent-900-rgb": "255,255,255",
    "--fuse-on-accent-950": "#FFFFFF",
    "--fuse-on-accent-950-rgb": "255,255,255",
    "--fuse-on-accent": "#FFFFFF",
    "--fuse-on-accent-rgb": "255,255,255",
    "--fuse-warn-50": "#fef2f2",
    "--fuse-warn-50-rgb": "254,242,242",
    "--fuse-warn-100": "#fee2e2",
    "--fuse-warn-100-rgb": "254,226,226",
    "--fuse-warn-200": "#fecaca",
    "--fuse-warn-200-rgb": "254,202,202",
    "--fuse-warn-300": "#fca5a5",
    "--fuse-warn-300-rgb": "252,165,165",
    "--fuse-warn-400": "#f87171",
    "--fuse-warn-400-rgb": "248,113,113",
    "--fuse-warn-500": "#ef4444",
    "--fuse-warn-500-rgb": "239,68,68",
    "--fuse-warn-600": "#dc2626",
    "--fuse-warn-600-rgb": "220,38,38",
    "--fuse-warn-700": "#b91c1c",
    "--fuse-warn-700-rgb": "185,28,28",
    "--fuse-warn-800": "#991b1b",
    "--fuse-warn-800-rgb": "153,27,27",
    "--fuse-warn-900": "#7f1d1d",
    "--fuse-warn-900-rgb": "127,29,29",
    "--fuse-warn-950": "#450a0a",
    "--fuse-warn-950-rgb": "69,10,10",
    "--fuse-warn": "#dc2626",
    "--fuse-warn-rgb": "220,38,38",
    "--fuse-on-warn-50": "#450a0a",
    "--fuse-on-warn-50-rgb": "69,10,10",
    "--fuse-on-warn-100": "#450a0a",
    "--fuse-on-warn-100-rgb": "69,10,10",
    "--fuse-on-warn-200": "#450a0a",
    "--fuse-on-warn-200-rgb": "69,10,10",
    "--fuse-on-warn-300": "#450a0a",
    "--fuse-on-warn-300-rgb": "69,10,10",
    "--fuse-on-warn-400": "#450a0a",
    "--fuse-on-warn-400-rgb": "69,10,10",
    "--fuse-on-warn-500": "#fef2f2",
    "--fuse-on-warn-500-rgb": "254,242,242",
    "--fuse-on-warn-600": "#FFFFFF",
    "--fuse-on-warn-600-rgb": "255,255,255",
    "--fuse-on-warn-700": "#FFFFFF",
    "--fuse-on-warn-700-rgb": "255,255,255",
    "--fuse-on-warn-800": "#FFFFFF",
    "--fuse-on-warn-800-rgb": "255,255,255",
    "--fuse-on-warn-900": "#FFFFFF",
    "--fuse-on-warn-900-rgb": "255,255,255",
    "--fuse-on-warn-950": "#FFFFFF",
    "--fuse-on-warn-950-rgb": "255,255,255",
    "--fuse-on-warn": "#FFFFFF",
    "--fuse-on-warn-rgb": "255,255,255",
    // "--fuse-icon-color": "#FFFFFF",
    "--fuse-button-text-color": "#FFFFFF"
  };
