<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->


<fuse-vertical-navigation class="light bg-white print:hidden w-[220px] min-w-[220px] max-w-[220px]"
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation"
    [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.svg">
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent bg-primary print:hidden justify-between">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon class="text-white" [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <div style="text-align: left;"  class="text-white text-2xl">{{tenantDetails?.TenantName}}</div>
        <!-- Components -->
        <div class="flex items-center pl-4 space-x-2.5 sm:space-x-4">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <messages></messages>
            <button class="w-auto" mat-icon-button (click)="navigateToProfile()">
                <img *ngIf="profilePicUrl?.length" [src]="profilePicUrl" alt="" class="rounded-full">
                <mat-icon *ngIf="!profilePicUrl?.length" class="text-white" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                <span class="text-white text-sm pl-1">{{ firstName }}</span>
            </button>
           
            <button mat-icon-button (click)="signOut()" matTooltip="Sign out">
                <mat-icon class="text-white" [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            </button>
            <!--  <button class="lg:hidden" mat-icon-button>
                <mat-icon class="text-white" [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <app-breadcrumb></app-breadcrumb>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center justify-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="text-sm text-center text-secondary">Copyright © 2024 Third Age Health Services Limited. All rights reserved</span>
    </div>

</div>