import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { DatafactoryService } from '../data-factory/data.factory';
import { RequestParams } from '../data-factory/request-param';

const profileApis = {
    updateProfile: environment.webApi + 'Identity/UpdateUserProfileDetails',
    getProfileInfo: environment.webApi + 'Identity/GetUserProfileDetails',
    updatePasswordInfo: environment.webApi + 'Identity/ChangePassword',
    updateEmailInfo: environment.webApi + 'Identity/ChangeEmailAddress',
    sendOtp: environment.webApi + 'Identity/SendOTP',
    verifyOtp: environment.webApi + 'authentication/VerifyOTP',
    save2FASettings: environment.webApi + 'SysUser/Save2FASettings'
};

@Injectable({
    providedIn: 'root',
})
export class ProfileApiService {
    userInfoList = [
        { name: 'Full Name :', key: 'FullName', type: '' },
        { name: 'Email ID :', key: 'Email', type: '' },
        { name: 'Mobile :', key: 'MobileNumber', type: 'mobile' },
        { name: 'Work :', key: 'WorkNumber', type: '' },
        { name: 'Extension :', key: 'ExtnNumber', type: '' },
        { name: 'Registration Number :', key: 'RegistrationNumber', type: '' },
        { name: 'HPI :', key: 'HPIID', type: '' },
        { name: 'Email Notification :', value: '', key: 'IsEmailNotificationsEnabled', type: 'boolean' },
        { name: '2FA :', value: '', key: 'Is2FAEnabled', type: 'boolean' }
    ]

    private profileInfoEv = new BehaviorSubject<any>([]);
    profileInfo = [];
    constructor(private dataFactoryService: DatafactoryService, private requestParams: RequestParams) { }

    get profileData$() {
        return this.profileInfoEv.asObservable();
    }

    set profileDataUpdate$(info: any[]) {
        this.profileInfo = info;
        console.log(this.profileInfo);
        this.profileInfoEv.next(this.profileInfo);
    } 

    getUpdateProfileForm(): Observable<any> {
        // return json from assets
        return this.dataFactoryService.getJSON(
            'assets/data/forms/profile-update-form.json'
        );
    }

    getUserInfo() {
        return this.userInfoList;
    }

    getProfileData() {
        return this.dataFactoryService.GetMethod(profileApis.getProfileInfo)
            .pipe(map((res: any) => {
                if (res?.length!! && res[0].responseCode === 1) {
                    this.profileDataUpdate$ = JSON.parse(res[0].responseData) || [];
                    return JSON.parse(res[0].responseData);
                }
                return [];
            }));
    }

    updateProfileForm(params: { OrgUserGuid: string, userDetailsJSON: string }) {
        return this.dataFactoryService.postMethod(profileApis.updateProfile, this.requestParams.ConstructRequest(params));
    }

    save2FASettings(enable2FA: boolean) {
        return this.dataFactoryService.postMethod(profileApis.save2FASettings, this.requestParams.ConstructRequest({Enable2FA: enable2FA ? "1" : "0"}));
    }

    updatePassword(params) {
        return this.dataFactoryService.postMethod(profileApis.updatePasswordInfo, params);
    }

    updateEmailInfo(params: { newEmailAddress: string, enteredOTP: string }) {
        return this.dataFactoryService.postMethod(profileApis.updateEmailInfo, this.requestParams.ConstructRequest(params));
    }

    sendOtpForEmail(params: { newEmailAddress: string, otpType: string }) {
        return this.dataFactoryService.postMethod(profileApis.sendOtp, this.requestParams.ConstructRequest(params));
    }

    verifyOtpForEmail(params: { userName: string, enteredOTP: string, otpType: string }) {
        return this.dataFactoryService.postMethod(profileApis.verifyOtp, this.requestParams.ConstructRequest(params));
    }
}
