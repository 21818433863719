<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">
        <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}} date-picker-cmp"
            style="width: 100%">
            <mat-label>{{control.label}}</mat-label>
            <input matInput [matDatepicker]="picker" [id]="control.id" [placeholder]="control.displayName"
                (dateChange)="onDateChange(control)" [required]="control.isRequired"
                [disabled]="control.isDisabled ? true : false" [formControl]="formControl" (input)="onDateInput($event)"
                [min]="control.minDate ? control.minDate : null">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error class="mat-error custom-error"
                *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
                <!-- <ng-container class="mat-error" *ngIf="formControl?.errors?.required">
                    {{control.label}} is required
                </ng-container> -->
                <!-- Display a custom error message for invalid date -->
                <ng-container class="mat-error" *ngIf="formControl?.errors?.pattern">
                    {{control.customErrorMsg}}
                </ng-container>
            </mat-error>
        </mat-form-field>
    </ng-container>

</ng-container>