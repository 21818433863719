import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
  standalone: true
})
export class ProfilePictureComponent implements OnChanges {
  @Input() firstName: string = '';
  @Input() lastName: string = '';
  @Input() noRandomColors: boolean;
  initials: string = '';
  randomColor: string = '';

  // Define an array of colors
  colors: string[] = [ '#12345372','#189AB4', '#05445E',   '#7CC9C8', '#5EBEBC','#A8A2EC', '#DBD78A', '#A29F66', '#CBD99A', '#687371', '#64706F'];

  ngOnChanges(): void {
     // Calculate initials from first and last name
     this.initials = this.getInitials(this.firstName, this.lastName);
     let colorIndex = 0;
     if (!this.noRandomColors) {
       // Select a random color from the array
       colorIndex = Math.floor(Math.random() * this.colors.length);
     }
     this.randomColor = this.colors[colorIndex];
  }

  private getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : '-';
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : '-';
    return firstInitial + lastInitial;
  }
}