<ng-container *ngIf="control as control">
    <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}}" [formGroup]="formGroup" style="width: 100%">
        <mat-label>{{control.label}}</mat-label>
        <input matInput type="text" numbersOnly [id]="control.id" [placeholder]="control.label"
            [(ngModel)]="control.value" [formControl]="formControl" (ngModelChange)="onChange(control)"
            [matTooltip]="control.tooltip" [maxlength]="control.maxlength" [minlength]="control.minlength"
            [required]="control.isRequired" [disabled]="control.isDisabled ? true : false">
        <mat-error class="mat-error" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <!-- <mat-error class="mat-error" *ngIf="formControl?.errors?.required">
                {{control.label}} is required
            </mat-error> -->
            <mat-error class="mat-error" *ngIf="formControl?.errors?.minlength">
                Sorry, {{control.label}} must be at least {{control.minlength}} characters long.
            </mat-error>
        </mat-error>
    </mat-form-field>
</ng-container>