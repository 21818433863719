import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject, filter, map, Observable, of } from 'rxjs';
import { IBreadcrumb } from './IBreadcrumb';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    private breadcrumbs: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<
        IBreadcrumb[]
    >([]);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                const breadcrumbs = this.setBreadcrumbs(
                    this.activatedRoute.root
                );
                this.breadcrumbs.next(breadcrumbs);
                this.saveToStorage(breadcrumbs);
            });

        // Load breadcrumbs from storage on application start
        const storedBreadcrumbs = this.getBreadcrumbsFromStorage();
        this.breadcrumbs.next(storedBreadcrumbs);
    }

    private setBreadcrumbs(
        route: ActivatedRoute,
        url: string = '',
        breadcrumbs: IBreadcrumb[] = []
    ): IBreadcrumb[] {
        const path = route.routeConfig?.path;
        if (path) {
            url += `/${path}`;
            breadcrumbs.push({
                label: route.routeConfig?.data?.breadcrumb || path,
                url,
                cannotNavigateBack: route.routeConfig?.data?.cannotNavigateBack || false
            });
        }

        if (route.firstChild) {
            this.setBreadcrumbs(route.firstChild, url, breadcrumbs);
        } else {
            this.breadcrumbs.next(breadcrumbs);
        }
        return breadcrumbs;
    }

    private saveToStorage(breadcrumbs: IBreadcrumb[]): void {
        localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
    }

    private getBreadcrumbsFromStorage(): IBreadcrumb[] {
        const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
        return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
    }

    getBreadcrumbs(): Observable<IBreadcrumb[]> {
        return this.breadcrumbs.asObservable().pipe(
            map((breadCrumbs) => {
                return breadCrumbs.map(({ label, url, cannotNavigateBack }) => {
                    const urlWithoutQuery = this.router.url.split("?")[0];
                    return { label, url, isActive: urlWithoutQuery === url, cannotNavigateBack };
                });
            })
        );
    }
}
