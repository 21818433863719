import { Injectable } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn
} from '@angular/forms';
import { IFormControl } from './form-controls/form-control.interface';
import { referenceFieldsChangeListenerForAppending, serializeValidators } from './form-controls/validators.const';
import { Observable, Subject } from 'rxjs';
import { DatafactoryService } from 'app/core/data-factory/data.factory';
import { FormControlMetaDataService } from './form-control-meta-data.service';

@Injectable({ providedIn: 'root' })
export class DynamicFormService {
    fg: FormGroup<{}>;
    constructor(private formBuilder: FormBuilder, private dataFactoryService: DatafactoryService, private formControlMetaDataService: FormControlMetaDataService) { }

    /* The `createForm` method in the `DynamicFormService` class is responsible for creating a dynamic
    form based on the provided array of `IFormControl` objects. */
    createForm(formControlsJSON: IFormControl[]) {
        formControlsJSON.forEach(control => {
            if (control.field) {
                this.addControl(control);
                this.addValidators(control.field, serializeValidators(control));
                if (control.isDisabled) {
                    this.fg.get(control.field).disable();
                }
            }

        });
        return this.fg;
    }


    /* The `addControl` method in the `DynamicFormService` class is responsible for adding a new form
    control to the form group (`fg`). It creates a new `FormControl` instance and adds it to the
    form group using the field name specified in the `control` parameter. If the form group does not
    exist yet, it creates a new form group. */
    addControl(control: any) {
        const newCtrl = new FormControl();
        if (!this.fg) {
            this.fg = new FormGroup({});
        }
        this.fg.addControl(control.field, newCtrl);
        if (control.value) {
            newCtrl.setValue(control.value);
            this.fg.controls[control.field].setValue(control.value);
        }
        return newCtrl;
    }

    /* The `addValidators` method in the `DynamicFormService` class is responsible for adding validator
    functions to a specific form control within the form group (`fg`). */
    addValidators(controlKey: string, validators: ValidatorFn[]) {
        const ctrl: FormControl = this.fg.controls[controlKey];
        if (ctrl) {
            validators.forEach(func => {
                if (!ctrl.hasValidator(func)) {
                    ctrl.addValidators(func);
                }
            });
        }
    }

    /* The `appendRefControlListeners` method in the `DynamicFormService` class is responsible for
    setting up listeners on a form control to watch for changes in reference fields. */
    appendRefControlListeners(controlKey, refFields) {
        const ctrl: FormControl = this.fg.controls[controlKey];
        referenceFieldsChangeListenerForAppending(refFields.split(','), ctrl, this.formControlMetaDataService);
    }

    /* The `getJSON` method in the `DynamicFormService` class is a method that makes an HTTP request to
    fetch JSON data from a specified URL. It calls the `getJSON` method of the `DatafactoryService`
    class, passing the URL as a parameter, to retrieve the JSON data. The method returns an
    Observable that can be subscribed to in order to receive the JSON data asynchronously. */
    getJSON(url): Observable<any> {
        // return json from assets
        return this.dataFactoryService.getJSON(
            url
        );
    }
}

