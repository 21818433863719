import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IDatepickerControl } from './datepicker-control.interface';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgFor, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  standalone: true,
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'dd/MM/yyyy',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
  ],
  imports: [
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgFor,
    NgIf
  ]
})
export class DatepickerComponent implements OnChanges {
  @Input() control: IDatepickerControl | null = null;

  @Input() formControl: FormControl | null = null;
  @Input() formGroup: FormGroup | null = null;

  @Input() controlIndex !: number;
  @Output() dateChange = new EventEmitter<IDatepickerControl>();

  today = new Date();

  /**
   *
   */
  constructor() {
    // this.today.setDate(this.today.getDate());
    // this.today = null;
  }

  ngOnChanges() {
    if (this.control && this.formControl) {
      if (this.control.value) {
        this.formControl.setValue(this.control.value)
      }
    }
  }

  onDateChange(control: IDatepickerControl): void {
    this.dateChange.emit(control);
  }

  onDateInput(event: Event) {
    // Custom date formatting and validation logic
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    // Date format validation if manual input is dd/mm/yyyy
    const datePatternSlash = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        const datePatternSlashWithOneDigit = /^(\d{1})\/(\d{1})\/(\d{4})$/
        const datePatternSlashWithMonthOneDigit = /^(\d{2})\/(\d{1})\/(\d{4})$/
        const datePatternSlashWithDateOneDigit = /^(\d{1})\/(\d{2})\/(\d{4})$/
        const datePatternHyphen = /^(\d{2})\-(\d{2})\-(\d{4})$/;
        const datePatternHyphenWithOneDigit = /^(\d{1})\-(\d{1})\-(\d{4})$/;
        const datePatternHyphenWithMonthOneDigit = /^(\d{2})\-(\d{1})\-(\d{4})$/;
        const datePatternHyphenWithDateOneDigit = /^(\d{1})\-(\d{2})\-(\d{4})$/;
        if (inputValue.match(datePatternSlash) || inputValue.match(datePatternSlashWithOneDigit) || inputValue.match(datePatternSlashWithMonthOneDigit) || inputValue.match(datePatternSlashWithDateOneDigit) || inputValue.match(datePatternHyphen) || inputValue.match(datePatternHyphenWithOneDigit) || inputValue.match(datePatternHyphenWithMonthOneDigit) || inputValue.match(datePatternHyphenWithDateOneDigit)) {
      // this.formControl.setErrors(null);
      const parts = (inputValue.match(datePatternSlash) || inputValue.match(datePatternSlashWithOneDigit) || inputValue.match(datePatternSlashWithMonthOneDigit) || inputValue.match(datePatternSlashWithDateOneDigit)) ? inputValue.split('/') : inputValue.split('-');
      const date = new Date(+parts[2], +parts[1] - 1, +parts[0]);
      this.formControl.setValue(date);
    }
  }

}
