import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import moment from 'moment';

@Directive({
  selector: '[appTimeFormatter]',
  standalone: true
})
export class TimeInputAutomateDirective {

    @Input('timeFormatControl') timeFormatControl: FormControl;
    @Input() placeholder: string;

    constructor(private el: ElementRef) {}
  
    ngOnInit(): void {
        console.log("inside time formetted irective", this.timeFormatControl)
      if (!this.timeFormatControl) {
        console.error('appTimeFormatter directive requires NgControl on the associated input element.');
        return;
      }

      if (this.el.nativeElement) {
        this.el.nativeElement.setAttribute("placeholder", this.placeholder ? this.placeholder : 'HH:MM AM/PM')
      }
  
    //   const inputElement = this.el.nativeElement;
    //   console.log(inputElement, this.timeFormatControl);
    //   inputElement.addEventListener('blur', () => {
    //     const inputValue = inputElement.value;
    //     const formattedTime = this.formatTime(inputValue);
    //     this.timeFormatControl.setValue(formattedTime);
    //   });
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value: string): void {
        const formattedTime = this.formatTime(value);
        
        if (formattedTime === 'Invalid input') {
        this.timeFormatControl.setErrors({ invalidTime: true });
        } else {
        this.timeFormatControl.setErrors(null);
        this.timeFormatControl.setValue(formattedTime);
        }
    }
  
    private formatTime(input: string): string {
      const momentTime = moment(input, ['h:mmA', 'h:mma', 'H:mm', 'hmmA', 'hmma', 'hmm']);
  
      if (momentTime.isValid()) {
        return momentTime.format('hh:mm A');
      }
  
      // Handle other scenarios here...
  
      return 'Invalid input';
    }
}