import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';
import { of } from 'rxjs';
import { AuthService } from '../auth.service';

export const InternalUsersGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const sweetAlert = inject(SweetAlert);
    const authService = inject(AuthService);
    authService.GetUserPermissionsByOrgIdForRole().subscribe(async (res) => {
        if (res?.length!! && res[0].responseCode === 1) {
            const permissionsList = JSON.parse(res[0].responseData);
            authService.updateUserPermissionsList = permissionsList;
            if (permissionsList?.length) {
                const findUserRole = permissionsList?.find(val => val.PermissionId === 1)
                if (findUserRole?.Ishavingaccess) {
                    return of(true);
                } else {
                    router.navigateByUrl('/sign-in');
                    sweetAlert.prompt("warning", 'Access Permission Denied', "OK");
                    return of(false);
                }
            }
        }
    })
    return of(true)
};
