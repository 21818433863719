import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LookuplistApiService } from 'app/core/lookuplist/lookuplist.api.service';
import { cloneDeep } from 'lodash';
import { IMultiSelectControl } from '../multi-select/multi-select-control.inerface';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-multi-select-with-badge',
  standalone: true,
  imports: [MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgFor,
    NgIf,
    MatChipsModule,
    MatIconModule
  ],
  templateUrl: './multi-select-with-badge.component.html',
  styleUrls: ['./multi-select-with-badge.component.scss']
})
export class MultiSelectWithBadgeComponent {
  @Input() control: IMultiSelectControl | null = null;

  @Input() formControl: FormControl | null = null;
  @Input() formGroup: FormGroup | null = null;

  @Input() controlIndex !: number;
  @Output() selectionChange = new EventEmitter<IMultiSelectControl>();
  selectedItems = [];


  constructor(private lookupListService: LookuplistApiService) {
    this.onSelectionChange(this.control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.control.lookupTypeReq?.length) {
      this.lookupListService.GetLookupListByTpe(this.control.lookupTypeReq,'{}')
        .subscribe(options => {
          if (!!this.control.lookupKey?.length) {
            const res = cloneDeep(options);
            res?.forEach(val => {
              val.key = val[this.control.lookupKey];
            });
            this.control.options = res || [];
            this.onSelectionChange(this.control);
          } else {
            this.control.options = options || [];
            this.onSelectionChange(this.control);
          }
        });
    } else {
      this.onSelectionChange(this.control);
    }
  }


  onSelectionChange(control: IMultiSelectControl): void {
    control?.options.forEach(element => {
      element.isChecked = control?.value?.includes(element.key) ? true : false;
    });
    this.selectionChange.emit(control);
    this.getSelectedOptionBadges();
  }

  getSelectedOptionBadges() {
    this.selectedItems = cloneDeep(this.control?.options?.map(option => option.isChecked));
  }

  removeItem(item: string): void {
    const findItem = this.selectedItems.find(selectedItem => selectedItem.value === item);
    if (findItem) {
      const index = this.selectedItems.indexOf(findItem);
      this.selectedItems.splice(index, 1);
      if (this.formControl?.value?.length) {
        const filteredValue = this.formControl.value.filter(selectedVal => selectedVal !== findItem.value);
        this.formControl.setValue(filteredValue);
      }
    }
  }

}
