import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, tap, Observable, of, switchMap, throwError, map, BehaviorSubject } from 'rxjs';
import { DatafactoryService } from '../data-factory/data.factory';
import { environment } from 'environments/environment';
import { RequestParams } from '../data-factory/request-param';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';

const authenticationApis = {
    login: environment.webApi + 'authentication/login',
    validateOTPfor2FA: environment.webApi + 'authentication/ValidateOTPfor2FA',
    sendOtpForPasswordReset: environment.webApi + 'authentication/sendOtpForPasswordReset',
    verifyOtp: environment.webApi + 'authentication/verifyotp',
    resetPassword: environment.webApi + 'authentication/resetpassword',
    tenantDetails: environment.webApi + 'Tenant/GetTenantDetails',
    GetUserPermissionsByOrgId: environment.webApi + 'Identity/GetUserPermissionsByOrgId'
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private tenantDetails = new BehaviorSubject<any>({});
    private userPermissionsList = new BehaviorSubject<any>({});

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient, private requestParams: RequestParams,
        private _userService: UserService,
        private dataFactoryService: DatafactoryService,
        private sweetAlert: SweetAlert
    ) {
    }

    get tenantDetails$(): Observable<any> {
        return this.tenantDetails.asObservable();
    }

    set updateTenantDetails(details) {
        this.tenantDetails.next(details);
    }

    get userPermissionsList$(): Observable<any> {
        return this.userPermissionsList.asObservable();
    }

    isHavingRespectivePermission(permissionId: number, showAlert, actionName = null): boolean {
        let msg = `Sorry! You don’t have a permission to {actionName}`;
        const permission = this.userPermissionsList.value.find(permission => permission.PermissionId === permissionId);
        if (showAlert && !permission?.Ishavingaccess) {
            this.sweetAlert.prompt("warning", msg.replace("{actionName}", actionName ? actionName : permission.Permission), "OK");
        }
        return permission && permission.Ishavingaccess;
    }

    set updateUserPermissionsList(list) {
        this.userPermissionsList.next(list);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    /**
      * Reset password
      *
      * @param userName The username for which to reset the password
      * @param password The new password
      * @param otp The OTP code
      */
    resetPassword(params: { userName: string, password: string, otp: string }): Observable<any> {
        // Create an HttpParams object to hold the key-value parameters


        // Make the API call with the parameters
        return this.dataFactoryService.postMethod(authenticationApis.resetPassword, this.requestParams.ConstructRequest(params)).pipe(
            tap((response: any) => {
                // Handle the response as needed
                // console.log('Password reset successful:', response);
                // You can modify this part to handle the response accordingly
            }),
            catchError((error: any) => {
                // Handle errors here
                // console.error('Error resetting password:', error);
                return throwError(error);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { UserName: string; Password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this.dataFactoryService.postMethod(authenticationApis.login, credentials).pipe(
            tap((response: any) => {
                if (response.responseCode === 1) {
                    const tokenResponse = JSON.parse(response.responseData);

                    if (tokenResponse.skip2FA && tokenResponse.canLogin) {
                        // Store the access token in the local storage
                        this.accessToken = tokenResponse.AccessToken;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        // Store the user on the user service
                        this._userService.user = tokenResponse.user;

                        // Get the user permissions
                        this.GetUserPermissionsByOrgId();
                    }


                }

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
     *validateOTPfor2FA
     *
     * @param credentials
     */
    validateOTPfor2FA(EnteredOTP: string, email: string, password: string): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this.dataFactoryService.postMethod(authenticationApis.validateOTPfor2FA, this.requestParams.ConstructRequest({ EnteredOTP, email, password })).pipe(
            tap((response: any) => {
                if (response.responseCode === 1) {
                    const tokenResponse = JSON.parse(response.responseData);
                    // Store the access token in the local storage
                    this.accessToken = tokenResponse.AccessToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = tokenResponse.user;

                    // Get the user permissions
                    this.GetUserPermissionsByOrgId();

                }

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
         * Send OTP for password reset
         *
         * @param userName The username for which OTP should be sent
         */
    sendOtpForPasswordReset(userName: string): Observable<any> {
        // Create the request payload
        const requestData = { params: [{ key: 'userName', val: userName }] };

        // Make the API call using the dataFactoryService.postMethod
        return this.dataFactoryService.postMethod(authenticationApis.sendOtpForPasswordReset, requestData).pipe(
            tap((response: any) => {

                // Handle the response as needed
                console.log('OTP sent successfully:', response);
                return response; // You can modify this as needed


            }),
            catchError((error: any) => {
                // Handle errors here
                console.error('Error sending OTP:', error);
                return throwError(error);
            })
        );
    }

    /**
        * Verify OTP
        *
        * @param userName The username for which OTP should be verified
        * @param enteredOTP The OTP entered by the user
        * @param otpType The type of OTP (e.g., "PWD" for password reset)
        */
    verifyOTP(params: { userName: string, enteredOTP: string, otpType: string }): Observable<any> {
        // Create the request parameters



        // Make the API call with the parameters
        return this.dataFactoryService.postMethod(authenticationApis.verifyOtp, this.requestParams.ConstructRequest(params)).pipe(
            tap((response: any) => {
                // Handle the response as needed
                if (response) {
                    return response; // You can modify this as needed
                }

            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Get the user permissions
                this.GetUserPermissionsByOrgId();

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.clear();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        if (this.accessToken) {
            this._authenticated = true;
        }
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        
        return of(false);
    }

    /**
     * The `getTenantDetails` function retrieves and stores tenant details from an API response in
     * localStorage.
     * @returns The `getTenantDetails()` function is returning the result of the HTTP GET request made
     * using `_httpClient.get(authenticationApis.tenantDetails)`. The function subscribes to the
     * observable returned by the HTTP request and processes the response data. If the response data
     * meets certain conditions (specifically, if it has a length greater than 0 and the `responseCode`
     * is 1), it parses the
     */
    getTenantDetails() {
        return this._httpClient.get(authenticationApis.tenantDetails).subscribe((res: any[]) => {
            if (res?.length!! && res[0].responseCode === 1) {
                const tenantDetails = JSON.parse(res[0].responseData);
                localStorage.setItem('TenantDetails', res[0].responseData);
                if (tenantDetails?.length && tenantDetails[0]?.TA?.length) {
                    localStorage.setItem('TenantGuid', tenantDetails[0]?.TA[0].FkTenantGuid);
                    localStorage.setItem('TimeZone', tenantDetails[0]?.TA[0].TimeZone);
                    this.updateTenantDetails = tenantDetails[0];
                }
            }
        });
    }

    /**
     * The function `GetUserPermissionsByOrgId` retrieves user permissions based on the selected
     * organization ID.
     * @param [selectedOrgGuid=null] - The `selectedOrgGuid` parameter in the
     * `GetUserPermissionsByOrgId` function is used to specify the organization ID for which you want
     * to retrieve user permissions. If `selectedOrgGuid` is provided, the function will make a POST
     * request to the `authenticationApis.GetUserPermissionsByOrgId
     * @returns The `GetUserPermissionsByOrgId` function is returning an Observable from the
     * `dataFactoryService.postMethod` call. This Observable is subscribed to, and if the response
     * array `res` has a length greater than 0 and the `responseCode` of the first element is 1, then
     * the function parses the `responseData` from the first element of `res` into a `permissions
     */
    GetUserPermissionsByOrgId(selectedOrgGuid = null) {
        return this.dataFactoryService.postMethod(authenticationApis.GetUserPermissionsByOrgId, this.requestParams.ConstructRequest({ selectedOrgGuid })).subscribe((res: any[]) => {
            if (res?.length!! && res[0].responseCode === 1) {
                const permissionsList = JSON.parse(res[0].responseData);
                this.updateUserPermissionsList = permissionsList;
            }
        });
    }

    GetUserPermissionsByOrgIdForRole(selectedOrgGuid = null) {
        return this.dataFactoryService.postMethod(authenticationApis.GetUserPermissionsByOrgId, this.requestParams.ConstructRequest({ selectedOrgGuid }))
    }
}
