<!-- Messages toggle -->
<button
    mat-icon-button
    (click)="openPanel()"
    #messagesOrigin>
    <!-- <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container> -->
    <mat-icon class="text-white" [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
</button>

<!-- Messages panel -->
<ng-template #messagesPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-white border-b">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Activity</div>
            <div class="ml-auto">
               
                <app-dynamic-forms #dynaForm class="inline-block w-[180px]" [formJSON]="formJson" [valueObj]="valueObj" (formInitializedEmt)="formInitializedEmt($event)"></app-dynamic-forms>
                
                <!-- <button
                    mat-icon-button
                    [disabled]="unreadCount === 0"
                    [matTooltip]="'Mark all as read'"
                    (click)="markAllAsRead()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:envelope-open'"></mat-icon>
                </button> -->
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Messages -->
            <ng-container *ngFor="let message of logs; trackBy: trackByFn">
                <div
                    class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{'unread': !message.read}">

                    <!-- Message with a link -->
                    <ng-container *ngIf="message.link">
                        <!-- Normal links -->
                        <ng-container *ngIf="!message.useRouter">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                [href]="message.link">
                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                            </a>
                        </ng-container>
                        <!-- Router links -->
                        <ng-container *ngIf="message.useRouter">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                [routerLink]="message.link">
                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- Message without a link -->
                    <ng-container *ngIf="!message.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                        </div>
                    </ng-container>

                    <!-- Actions -->
                    <div class="hidden relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Indicator -->
                        <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            (click)="toggleRead(message)"
                            [matTooltip]="message.read ? 'Mark as unread' : 'Mark as read'">
                            <span
                                class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': message.read,
                                            'bg-primary': !message.read}"></span>
                        </button>
                        <!-- Remove -->
                        <button
                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                            mat-icon-button
                            (click)="delete(message)"
                            [matTooltip]="'Remove'">
                            <mat-icon
                                class="icon-size-4"
                                [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Message content template -->
                <ng-template #messageContent>
                    <!-- Icon -->
                    <ng-container *ngIf="message.FirstName && !message.profilePic">
                        <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <!-- <mat-icon
                                class="icon-size-5"
                                [svgIcon]="message.icon">
                            </mat-icon> -->
                            <app-profile-picture [firstName]="message.FirstName"
                            [lastName]="message.LastName"></app-profile-picture>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="message.profilePic">
                        <img
                            class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="message.profilePic"
                            [alt]="'Message image'">
                    </ng-container>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <div class="mb-2 text-sm leading-none text-secondary">
                            {{message.EventTimeStamp | customDateTZ:'MMM dd, h:mm a'}}
                        </div>
                        <ng-container *ngIf="message.title">
                            <div
                                class="font-semibold line-clamp-1"
                                [innerHTML]="message.FirstName + ' ' + message.LastName"></div>
                        </ng-container>
                        <ng-container *ngIf="message.EventContent">
                            <div
                                class="line-clamp-2"
                                [innerHTML]="message.EventContent"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No messages -->
            <ng-container *ngIf="!logs || !logs.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon
                            class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:inbox'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No messages</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have messages, they will appear here.</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
