import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InternalUsersGuard } from './core/auth/guards/internal-users.guard';
import { RolesGuard } from './core/auth/guards/roles.guard';
import { WildCardComponent } from './wild-card/wild-card.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/rounds/search'
    { path: '', pathMatch: 'full', redirectTo: 'rounds/search' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
            { path: 'register', loadChildren: () => import('app/modules/auth/register/register.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'rounds', data: { breadcrumb: 'Rounds' }, loadChildren: () => import('app/modules/rounds/rounds.route') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard, RolesGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'roles', data: { breadcrumb: 'Roles' }, loadChildren: () => import('app/modules/roles/roles.route') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard, InternalUsersGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'manage-users', data: { breadcrumb: 'Manage Users' }, loadChildren: () => import('app/modules/manage-users/manage-users.route') },
        ]
    },

    // Round Details
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'round-details', data: { breadcrumb: 'Rounds' }, loadChildren: () => import('app/modules/round-details/round-details.routes') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'profile', data: { breadcrumb: 'My Profile' }, loadChildren: () => import('app/modules/profile/profile.route') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'tasks', data: { breadcrumb: 'Tasks' }, loadChildren: () => import('app/modules/tasks/tasks.routes') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'facilities', data: {breadcrumb: 'Facilities'}, loadChildren: () => import('app/modules/facilities/facilities.routes') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        resolve: {
            initialData: initialDataResolver
        },
        component: LayoutComponent,
        children: [
            { path: 'patient-details', data: { breadcrumb: '' }, loadChildren: () => import('app/modules/patient-details/patient-details.routes') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        resolve: {
            initialData: initialDataResolver
        },
        component: LayoutComponent,
        children: [
            { path: 'patients', data: { breadcrumb: 'Patients' }, loadChildren: () => import('app/modules/patients/patient.routes') },
        ]
    },

    { path: '**', component: WildCardComponent }
];
