<ng-container *ngIf="control as control">
    <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}}" [formGroup]="formGroup"
        style="width: 100%">
        <mat-label>{{control.label}}</mat-label>
        <mat-chip-list #chipList> <mat-chip *ngFor="let item of selectedItems" [removable]="true"
                (removed)="removeItem(item)"> {{ item.lable }} <mat-icon matChipRemove>cancel</mat-icon> </mat-chip>
        </mat-chip-list>
        <mat-select [placeholder]="control.label" [(ngModel)]="control.value" [id]="control.id"
            [formControl]="formControl" multiple (selectionChange)="onSelectionChange(control)"
            [required]="control.isRequired" [disabled]="control.isDisabled ? true : false">
            <mat-option *ngFor="let option of control.options" [value]="option.key">
                {{ option.lable }}
            </mat-option>
        </mat-select>
        <!-- <mat-error class="mat-error" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <ng-container class="mat-error" *ngIf="formControl?.errors?.required">
                {{control.label}} is required
            </ng-container>
        </mat-error> -->
    </mat-form-field>
</ng-container>