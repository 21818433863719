import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { themeShades } from './app.config';
import { AuthService } from './core/auth/auth.service';
import { ProfileApiService } from 'app/core/profile/profile.api.service';
import { Title } from '@angular/platform-browser';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';
import { AppStateService } from '@fuse/services/app-state-service/app.state.service';
import { Location } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SharedService } from './core/data-factory/SharedService';
import { Subject, interval, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgIdleKeepaliveModule]


})

export class AppComponent implements OnInit, OnDestroy {
  unsubscribeAll = new Subject<void>();
  prevState: string;

  /**
   * Constructor
   */
  constructor(
    private renderer: Renderer2,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    public location: Location,

    private profileApiService: ProfileApiService,
    public title: Title,
    private idle: Idle,
    public alert: SweetAlert,
    private appStateService: AppStateService) {
    this.bindThemeColors();
    this.authService.getTenantDetails();
    this.authService.check().pipe(takeUntil(this.unsubscribeAll)).subscribe(isAuthenticated => {
      if (isAuthenticated) {
        // Get the user profile and permissions

        this.profileApiService.getProfileData().pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
          if (data.length > 0) {
            const firstName = data[0]?.FirstName;
            const orgUserId = data[0]?.orgUserId;
            const isInternalUser = data[0]?.isInternalUser;
            localStorage.setItem('isInternalUser', isInternalUser);

            if (firstName) {
              localStorage.setItem('FirstName', firstName);
            }
            if (orgUserId) {
              localStorage.setItem('OrgUserId', orgUserId);
            }
          }
        });


        this.authService.GetUserPermissionsByOrgId();
      }
    });
    interval(3000).pipe(takeUntil(this.unsubscribeAll)).subscribe(_ => this.checkInternetConnectivity());
  }
  ngOnInit() {
    this.idle.setIdle(600);
    this.idle.setTimeout(600);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.alert.prompt('warning', 'Your session is about to expire for privacy reasons. To remain logged in, please click the button below. <br><br>ref: 501', 'Stay logged in')
        .then(r => {
          this.idle.watch();
        });
    });
    this.idle.onTimeout.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.forceLogout();
    });
    this.router.events.pipe(takeUntil(this.unsubscribeAll)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        setTimeout(()  => {
          window.scrollTo(0, 0);
        }, 500)
        const currentRouteName = this.getCurrentRouteName();
        localStorage.setItem('currentRoute', currentRouteName);
        if (this.location.path() === '/sign-in' || this.location.path() === '/sign-out'
          || this.location.path() === '/forgot-password' || this.location.path() === '/register' || this.location.path() === "/pageNotFound") {
          this.idle.stop();
        } else {
          this.reset();
        }


      }
    });
  }

  checkInternetConnectivity() {
    if (this.prevState === "false" && navigator.onLine) {
      this.alert.showToast("success", "Back online!", 3600);
    }
    if (!navigator.onLine) {
      this.alert.showToast("error", "No internet connection!", 3600);
    }
    this.prevState = `${navigator.onLine}`;
  }

  reset() {
    this.idle.watch();
  }
  // getCurrentRouteName(): string {
  //   let route = this.router.routerState.snapshot.root;
  //   while (route.component) {
  //     route = route.firstChild;
  //   }
  //   return route.routeConfig.path;
  // }

  getCurrentRouteName(): string {
    let route = this.router.routerState.snapshot.root;

    while (route) {
      if (!route.component) {
        break; // Exit the loop if there's no component (null route)
      }
      route = route.firstChild;
    }

    // Check if route is not null before accessing its properties
    if (route) {
      return route.routeConfig ? route.routeConfig.path : '';
    }

    return ''; // Return an empty string if there is no valid route
  }


  bindThemeColors() {
    Object.keys(themeShades).forEach(color => {
      if (themeShades.hasOwnProperty(color)) {
        document.body.style.setProperty(color, themeShades[color]);
      }
    });
  }

  forceLogout() {

    this.sharedService.clearLocalStorageExcept(['TenantGuid', 'TenantDetails', 'TimeZone']);

    this.alert.prompt('info', 'Your session has expired due to inactivity. <br><br>ref: 502', 'Login again');
    const reDirectPath = window.location.origin + '/sign-in';
    window.location.replace(reDirectPath ? window.location.origin + '/sign-in' : window.location.origin + '/sign-in');

  }
  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

}
