<ng-container *ngIf="control as control">
    <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}}" [formGroup]="formGroup"
        style="width: 100%">
        <mat-label>{{control.label}}</mat-label>
        <mat-select [placeholder]="control.label" [(ngModel)]="control.value" [id]="control.id"
            [formControl]="formControl" multiple (selectionChange)="onSelectionChange(control)"
            [required]="control.isRequired" [disabled]="control.isDisabled ? true : false"
            [panelClass]="control.panelClass">
            <mat-select-trigger>
                <span>{{labels?.[0] | slice:0:20}} <span
                    *ngIf="labels?.[0]?.slice(0, 20)?.length >= 20">...</span></span>
                <span class="example-additional-selection" *ngIf="(labels?.length || 0) > 1" customToolTip
                    [contentTemplate]="customTooltip">
                    (+{{(labels?.length || 0) - 1}} {{labels?.length === 2 ? 'other' : 'others'}})
                </span>
                <ng-template #customTooltip>
                    <ul>
                        <li class="list-style" *ngFor="let label of labels">{{label}}
                        </li>
                    </ul>
                </ng-template>
            </mat-select-trigger>
            <mat-option *ngFor="let option of control.options" [value]="option.key">
                {{ option.lable }}
            </mat-option>
        </mat-select>
        <!-- <mat-error class="mat-error" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            <ng-container class="mat-error" *ngIf="formControl?.errors?.required">
                {{control.label}} is required
            </ng-container>
        </mat-error> -->
    </mat-form-field>
</ng-container>