<form [formGroup]="formGroup">
    <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}}" style="width: 100%">
        <mat-label>{{control.label}}</mat-label>
        <input [placeholder]="control.label"
               [id]="control.id"
               [matAutocomplete]="auto"
               [formControl]="formControl"
               matInput
               [matTooltip]="control.tooltip"
               (ngModelChange)="filteredOptions = filter(control)"
               [required]="control?.isRequired"
               [disabled]="control?.isDisabled ? true : false">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions" [value]="option.key">
                {{option.value}}
            </mat-option>
        </mat-autocomplete>
        <mat-error class="mat-error" *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched)">
            <ng-container *ngIf="formControl?.errors?.required">
                {{control.label}} is required
            </ng-container>
        </mat-error>
    </mat-form-field>
</form>
