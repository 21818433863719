import { CommonModule, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IParagraphControl } from './paragraph-control.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { LookuplistApiService } from 'app/core/lookuplist/lookuplist.api.service';
import { Subject, combineLatest, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { getRespectiveControlValueChangeListener } from '../validators.const';
import { FormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-paragraph',
    templateUrl: './paragraph.component.html',
    styleUrls: ['./paragraph.component.scss'],
    standalone: true,
    imports: [NgIf, CommonModule],
})
export class ParagraphComponent implements OnChanges, OnChanges {
    @Input() control: IParagraphControl | null = null;
    @Input() formControl: FormControl;
    private unsubscribe: Subject<void> = new Subject<void>();
    private immutableControlObject: IParagraphControl | null = null;
    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    constructor(
        private sanitizer: DomSanitizer,
        private lookupListService: LookuplistApiService
    ) {}

    markBindingMarksToNA() {
     this.control.content = cloneDeep(this.immutableControlObject).content.toString().replace(/\{[^}]*\}/g, 'NA');
    }

    ngOnChanges(changes: SimpleChanges): void {
      console.log(this.control, this.formControl);
      // Let's clone a deep copy of original so that we don't lose bindings due to content replacments. Eg: {bindingProperty}
      this.immutableControlObject = cloneDeep(this.control);
      if (this.control.refFields?.length) {
        this.markBindingMarksToNA();
      }
        if (!!this.control.lookupTypeReq?.length) {
            combineLatest(
                getRespectiveControlValueChangeListener(
                    this.control.refFields?.split(','),
                    this.formControl
                )
            )
                .pipe(
                    filter((val) => val.every((ele) => ele)),
                    distinctUntilChanged((prev, curr) => {
                        // Custom comparison function to only enter if the value is changed.
                        return Array.isArray(prev) ? JSON.stringify(prev) === JSON.stringify(curr) : prev === curr;
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((values) => {
                  console.log(values);
                  const json = {};
                    const searchJson = values.map((value, index) => {
                        json[this.control.refFields.split(',')[index]] = Array.isArray(value) ? value.join(',') : value;
                        if (
                            this.control.refFields.split(',')[index] ===
                            'FacilityId'
                        ) {
                            json['selectedOrgGuid'] = value;
                        }
                        return json;
                    });
                    console.log(searchJson, "final json");
                    this.lookupListService
                        .GetLookupListByTpe(
                            this.control.lookupTypeReq,
                            JSON.stringify(
                                !!searchJson?.length ? searchJson[0] : {}
                            )
                        )
                        .subscribe((res) => {
                            if (
                                this.control.customName === 'create-round-para'
                            ) {
                                const facilityPatientsCount =
                                    res?.[0]?.lable?.[0]?.FacilityPatients;
                                const flaggedPatientsCount =
                                    res?.[0]?.lable?.[0]?.FlaggedPatients;
                                const newPatientsCount =
                                    res?.[0]?.lable?.[0]?.NewPatients;
                                const mutableObj = cloneDeep(this.immutableControlObject); // so that it get's same value as before chaging the contents
                                this.control.content = mutableObj.content
                                    .toString()
                                    .replace(
                                        '{facilityPatientsCount}',
                                        facilityPatientsCount
                                    );
                                this.control.content = this.control.content
                                    .toString()
                                    .replace(
                                        '{flaggedPatientsCount}',
                                        flaggedPatientsCount
                                    );
                                this.control.content = this.control.content
                                    .toString()
                                    .replace(
                                        '{newPatientsCount}',
                                        newPatientsCount
                                    );
                            }
                            console.log('res', res);
                            // console.log('facilityPatientsCount', this.facilityPatientsCount)
                        });
                });
        }
    }

    getSafeHtml(content: string) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}
