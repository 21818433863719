import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map, of } from 'rxjs';
import { DatafactoryService } from '../data-factory/data.factory';
import { RequestParams } from '../data-factory/request-param';

const lookupListsApis = {
    lookupList: (type, searchJson = '{}') => environment.webApi + `Lookup/GetLookupList?lookupTypeReq=${type}&searchJson=${searchJson}`,
    lookupListForOrg: environment.webApi + 'Lookup/GetLookupListForOrg'
};

@Injectable({
    providedIn: 'root',
})
export class LookuplistApiService {
    constructor(private dataFactoryService: DatafactoryService, private requestParams: RequestParams) {}

    GetLookupListByTpe(type: string, searchJson: string) {

        if(type!='OrguserRoleForOrg' && type!='Wing')
        {
        return this.dataFactoryService
            .GetMethod(lookupListsApis.lookupList(type, searchJson))
            .pipe(map(data => {
                if (data[0].response === 'success') {
                    return JSON.parse(data[0].responseData);
                }
            }))
        }
        else {
            var searchValue = JSON.parse(searchJson);
            var localselectedOrgGuid = localStorage.getItem('currentRoute')!='facilities' ? (searchValue?.selectedOrgGuid ?  searchValue?.selectedOrgGuid : localStorage.getItem('selectedOrgGuid')) : localStorage.getItem('selectedOrgGuid');
            
            const params = { lookupTypeReq: type, selectedOrgGuid: localselectedOrgGuid};
            return this.dataFactoryService
                .postMethod(lookupListsApis.lookupListForOrg, this.requestParams.ConstructRequest(params))
                .pipe(map(data => {
                    if (data[0].response === 'success') {
                        return JSON.parse(data[0].responseData);
                    }
                }));
        }
    }

    
}