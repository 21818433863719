import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'customDateTZ',
    standalone: true,
})
export class DateTimeZonePipe implements PipeTransform {
    constructor() {}

    transform(value: any, format: string): any {
        // Ensure the value is a valid date
        if (
            value &&
            (value instanceof Date || !isNaN(new Date(value).getTime()))
        ) {
            const date = new Date(value);
            const utcFormattedDate = this.formatWithOffset(date);
            const datePipe = new DatePipe('en-US');
            const formattedDate = datePipe.transform(
                utcFormattedDate,
                format ? format : 'medium'
            );
            return formattedDate;
        }
        return value;
    }

    formatWithOffset(date: Date): string {
        const tenantDetails = JSON.parse(localStorage.getItem('TenantDetails'));
        let offset = this.getLoggedInUserTimeZoneOffset(); // default to logged in user's timezone.
        if (tenantDetails?.length && tenantDetails[0]?.TA?.length) {
            const timeZone = tenantDetails[0]?.TA[0].TimeZone;
            if (timeZone) {
                if (timeZone.includes(':')) {
                    offset = timeZone.replace(/^UTC/, '');
                } else {
                    // Handle the case where the offset is like '+13' by adding ':00'
                    offset = timeZone.replace(/^UTC/, '') + ':00';
                }
            }
        }
        const [hours, minutes] = offset.split(':');
        const offsetMilliseconds =
            (parseInt(hours, 10) * 60 + parseInt(minutes, 10)) * 60 * 1000;
        const formattedDate = new Date(date.getTime() + offsetMilliseconds);
        return formattedDate.toISOString();
    }

    getLoggedInUserTimeZoneOffset() {
        const date = new Date();
        const timezoneOffsetMinutes = -date.getTimezoneOffset();

        const hours = Math.floor(timezoneOffsetMinutes / 60);
        const minutes = timezoneOffsetMinutes % 60;

        const offsetString =
            (hours >= 0 ? '+' : '-') +
            padZero(Math.abs(hours)) +
            ':' +
            padZero(Math.abs(minutes));

        function padZero(num) {
            return num < 10 ? '0' + num : num.toString();
        }

        return offsetString;
    }
}
