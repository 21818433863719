<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">
        <input class="timePicker w-full h-[40px]" [ngClass]="{'highlight-time': (formControl?.invalid && (formControl?.dirty || formControl?.touched))}" [type]="'text'" [id]="control.id"
            pattern="{{control.pattern}}" [placeholder]="control.label ? control.label : 'HH:MM AM/PM'" [formControl]="formControl" appTimeFormatter [timeFormatControl]="formControl"
            (change)="onChange($event)" [matTooltip]="control.tooltip" [required]="control.isRequired"
            [disabled]="control.isDisabled ? true : false">
        <mat-error class="mat-error" *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched)">
            <ng-container class="mat-error" *ngIf="formControl?.errors?.minlength">
                {{control.label}} must be at least {{control.minlength}} characters long.
            </ng-container>
            <ng-container class="mat-error" *ngIf="formControl?.errors?.pattern">
                {{control.customErrorMsg}}
            </ng-container>
            <ng-container class="mat-error" *ngIf="formControl?.errors?.compareWith">
                {{control.compareWithMsg}}
            </ng-container>
        </mat-error>
    </ng-container>