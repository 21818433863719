<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">

        <mat-label>{{control.label}}</mat-label>
        <div *ngFor="let option of control.options" class="checkbox-container">
            <mat-checkbox color="primary" [class]="control.customClass" [matTooltip]="option.tooltip"
                [(ngModel)]="option.isChecked" (change)="onSelectionChange(control)" [required]="control.isRequired"
                [disabled]="control.isDisabled ? true : false" [formControl]="formControl">
                {{option.value}}
            </mat-checkbox>
        </div>

    </ng-container>
</ng-container>