<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">
        <mat-form-field [appearance]="control.style" class="mat-form-field relative {{control.customClass}} textarea-after" style="width: 100%" [attr.data-content]="(formControl?.value?.length ?
            formControl?.value?.length : 0 ) +'/'+control.maxlength">
            <mat-label>{{control.label}}</mat-label>            
            <textarea matInput textTrim type="text" [id]="control.id" pattern="{{control.pattern}}" [placeholder]="control.label"
                [(ngModel)]="control.value" [formControl]="formControl" (change)="onChange(control)" [matTooltip]="control.tooltip"
                [maxlength]="control.maxlength" [rows]="control.rows" [minlength]="control.minlength" [required]="control.isRequired"
                [disabled]="control.isDisabled ? true : false"></textarea>
            <mat-error class="mat-error" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
            
                <ng-container class="mat-error" *ngIf="formControl?.errors?.minlength">
                    Sorry, {{control.label}} must be at least {{control.minlength}} characters long.
                </ng-container>
                <ng-container class="mat-error" *ngIf="formControl?.errors?.pattern">
                    {{control.customErrorMsg}}
                </ng-container>
            </mat-error>
        </mat-form-field>
    </ng-container>

</ng-container>