import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ITextFieldControl } from './textfield-control.interface';
import { TextTrimDirective } from './text-trim.directive';

@Component({
    selector: 'app-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.scss'],
    standalone: true,
    imports: [MatInputModule, ReactiveFormsModule, MatTooltipModule, NgIf, TextTrimDirective],
})
export class TextfieldComponent {
    @Output() selectionChange = new EventEmitter<ITextFieldControl>();
    @Input() control: ITextFieldControl | null = null;

    @Input() formControl: FormControl | null = null;
    @Input() formGroup: FormGroup | null = null;

    @Input() controlIndex!: number;

    onChange(control: ITextFieldControl): void {
        this.selectionChange.emit(control);
    }
}
