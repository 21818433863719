import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IMultiSelectControl } from './multi-select-control.inerface';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LookuplistApiService } from 'app/core/lookuplist/lookuplist.api.service';
import { cloneDeep } from 'lodash';
import { Subject, combineLatest, filter, takeUntil, Observable, startWith, Subscription, distinctUntilChanged } from 'rxjs';
import { getControlName, getRespectiveControlValueChangeListener } from '../validators.const';
import { FormControlMetaDataService } from '../../form-control-meta-data.service';
import { CustomToolTipComponent } from 'app/core/directives/custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from 'app/core/directives/tool-tip-renderer.directive';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgFor,
    NgIf,
    CustomToolTipComponent, ToolTipRendererDirective,
    CommonModule
  ]
})
export class MultiSelectComponent implements OnDestroy {
  @Input() control: IMultiSelectControl | null = null;

  @Input() formControl: FormControl | null = null;
  @Input() formGroup: FormGroup | null = null;

  @Input() controlIndex !: number;
  @Output() selectionChange = new EventEmitter<IMultiSelectControl>();
  formValueControlChangesSubscription: Subscription;
  private unsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  labels = [];
  constructor(private lookupListService: LookuplistApiService, private formControlMetaDataService: FormControlMetaDataService) {
    this.onSelectionChange(this.control);

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes?.formControl?.currentValue) {
      this.updateControlLabel(this.formControl)
    }

    if (!!this.control.lookupTypeReq?.length) {
      if (!!this.control.refFields?.length) {
        // TO DO: This code need's to be refactored (Should be global method for all the dropdown controls)
        combineLatest(getRespectiveControlValueChangeListener(this.control.refFields?.split(","), this.formControl)).pipe(filter(val => val.every(ele => ele)), distinctUntilChanged((prev, curr) => {
          // Custom comparison function to only enter if the value is changed.
          return Array.isArray(prev) ? JSON.stringify(prev) === JSON.stringify(curr) : prev === curr;
        }), takeUntil(this.unsubscribe))
          .subscribe((values) => {
            const searchJson = values.map((value, index) => {
              const json = {};
              json[this.control.refFields.split(",")[index]] = value;
              if (this.control.refFields.split(",")[index] === 'FacilityId') {
                json['selectedOrgGuid'] = value;
              }
              if (this.control.moduleName) {
                json['moduleName'] = this.control.moduleName;
              }
              return json;
            });
            this.lookupListService.GetLookupListByTpe(this.control.lookupTypeReq, JSON.stringify(!!searchJson?.length ? searchJson[0] : {}))
              .subscribe(options => {
                const res = cloneDeep(options);
                this.control.options = this.control.lookupTypeReq === 'FacilitiesbyGroup' ? (res || [])?.filter(opt => opt?.IsHavingPermission) : res || [];
                /*  This if condition is for updating the key value with other key value. For example In roles component we are changing the key value with AccessPermissionBitMap value */
                if (!!this.control.lookupKey?.length) {
                  res?.forEach(val => {
                    val.key = val[this.control.lookupKey];
                  });
                }
                this.control.options = res || [];

                if (this.formControl?.value && 
                  !this.control.options.some((opt) =>
                    this.formControl?.value?.some(
                      (v) => v?.toString() === opt.key?.toString()
                    )
                  )
                ) {
                  this.formControl.reset(); // Clear previous selection as new dropdownlist is generate.
                } else {
                  // reset and assign only which are there in the options.
                  const clonedValue = cloneDeep(this.formControl.value) as [];
                  if (!!clonedValue?.length) {
                    this.formControl.reset();
                    const options = this.control.options.filter(
                      (opt) =>
                        clonedValue.map((v: any) => v?.toString()).indexOf(opt.key?.toString()) >= 0
                    );
                    if (!!options?.length) {
                      this.formControl.setValue(options.map(opt => opt.key));
                    }
                  }
                }

                this.formControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
                this.onSelectionChange(this.control);
              });
          });
      } else {
        const searchJson = this.control.moduleName ? { moduleName: this.control.moduleName } : {};
        this.lookupListService.GetLookupListByTpe(this.control.lookupTypeReq, JSON.stringify(searchJson))
          .subscribe(options => {
            if (!!this.control.lookupKey?.length) {
              const res = cloneDeep(options);
              res?.forEach(val => {
                val.key = val[this.control.lookupKey];
              });
              this.control.options = res || [];
              this.formControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
              this.onSelectionChange(this.control);
            } else {
              this.control.options = options || [];
              this.control.value = [];
              this.formControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
              this.onSelectionChange(this.control);
            }

          });
      }
    } else {
      this.formControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
      this.onSelectionChange(this.control);
    }
  }


  onSelectionChange(control: IMultiSelectControl): void {
    control?.options.forEach(element => {
      element.isChecked = control?.value?.includes(element.key) ? true : false;
    });
    this.selectionChange.emit(control);
  }

  updateControlLabel(formControl) {
    if (this.formValueControlChangesSubscription) {
      this.formValueControlChangesSubscription.unsubscribe();
    }
      this.formValueControlChangesSubscription = this.formControl?.valueChanges.subscribe(val => {
        this.labels = [];
        const refOptions = this.formControlMetaDataService.getMetadata(this.formControl, getControlName(this.formGroup as FormGroup, this.formControl));
        val?.forEach(refCtrl => {
          if (refCtrl && refOptions) {
            const findLabel = refOptions?.find(opt => opt.key === refCtrl)?.lable;
            this.labels.push(findLabel);
          }
        })
      })
    }
  
}
