<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup" >
        <label class="mat-radio-label text-[#1E293B] text-base font-medium" [id]="control.id"
            [ngClass]="{'required': control.isRequired}">{{control.label}}</label>
        <mat-radio-group class="radio-group {{control.customClass}}" [id]="control.id" [matTooltip]="control.tooltip"
         (change)="onSelectionChange(control)" [formControl]="formControl"
            [disabled]="control.isDisabled ? true : false">
            <mat-radio-button *ngFor="let option of control.options" [value]="option.key">
                {{ option.lable }}
            </mat-radio-button>
        </mat-radio-group>
    </ng-container>
</ng-container>