import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ISelectControl } from './select-control.interface';
import { LookuplistApiService } from 'app/core/lookuplist/lookuplist.api.service';
import { getRespectiveControlValueChangeListener } from '../validators.const';
import { combineLatest, Subject, takeUntil, filter, distinctUntilChanged } from 'rxjs';
import { FormControlMetaDataService } from '../../form-control-meta-data.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    standalone: true,
    imports: [
        MatSelectModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgFor,
        NgIf
    ]
})
export class SelectComponent implements OnChanges, OnDestroy {
    @Input() control: ISelectControl | null = null;

    @Input() formControl: FormControl | null = null;
    @Input() formGroup: FormGroup | null = null;

    @Input() controlIndex !: number;
    @Output() selectionChange = new EventEmitter<ISelectControl>();
    private unsubscribe: Subject<void> = new Subject<void>();

    /**
     *
     */
    constructor(private lookupListService: LookuplistApiService, private FormControlMetaDataService: FormControlMetaDataService) {
        this.selectDefault();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.control.lookupTypeReq?.length) {
            if (!!this.control.refFields?.length) {
                // TO DO: This code need's to be refactored (Should be global method for all the dropdown controls)
                combineLatest(getRespectiveControlValueChangeListener(this.control.refFields?.split(","), this.formControl)).pipe(filter(val => val.every(ele => ele)), distinctUntilChanged((prev, curr) => {
                    // Custom comparison function to only enter if the value is changed.
                    return Array.isArray(prev) ? JSON.stringify(prev) === JSON.stringify(curr) : prev === curr;;
                  }), takeUntil(this.unsubscribe))
                    .subscribe((values) => {
                        const json = {};
                        const searchJson = values.map((value, index) => {
                            json[this.control.refFields.split(",")[index]] = value;
                            if (this.control.refFields.split(",")[index] === 'FacilityId') {
                                json['selectedOrgGuid'] = value;
                            }
                            if (this.control.moduleName) {
                                json['moduleName'] = this.control.moduleName;
                            }
                            return json;
                        });
                        this.lookupListService.GetLookupListByTpe(this.control.lookupTypeReq, JSON.stringify(!!searchJson?.length ? searchJson[0] : {}))
                            .subscribe(options => {
                                this.control.options = this.control.lookupTypeReq === 'FacilitiesbyGroup' ? (options || [])?.filter(opt => opt?.IsHavingPermission) : options || [];
                                if (!this.control.options.some(opt => opt.key?.toString() === this.formControl.value?.toString())) {
                                    this.formControl.reset(); // Clear previous selection as new dropdownlist is generate.
                                } else {
                                    // reset and assign only which are there in the options.
                                    const clonedValue = cloneDeep(this.formControl.value);
                                    if (clonedValue?.toString()?.length) {
                                        this.formControl.reset();
                                        const option = this.control.options.find(opt => opt.key?.toString() === clonedValue?.toString());
                                        if (option) {
                                            this.formControl.setValue(option.key);
                                        }
                                    }
                                }
                                this.FormControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
                                this.selectDefault();
                            });
                    });
            } else {
                const searchJson = this.control.moduleName ? { moduleName: this.control.moduleName } : {};
                this.lookupListService.GetLookupListByTpe(this.control.lookupTypeReq, JSON.stringify(searchJson))
                    .subscribe(options => {
                        this.control.options = options || [];
                        if (!this.control.options.some(opt => opt.key?.toString() === this.formControl.value?.toString())) {
                            this.formControl.reset(); // Clear previous selection as new dropdownlist is generate.
                        } else {
                            // reset and assign only which are there in the options.
                            const clonedValue = cloneDeep(this.formControl.value);
                            if (clonedValue?.toString()?.length) {
                                this.formControl.reset();
                                const option = this.control.options.find(opt => opt.key?.toString() === clonedValue?.toString());
                                if (option) {
                                    this.formControl.setValue(option.key);
                                }
                            }
                        }
                        this.FormControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
                        this.selectDefault();
                    });
            }

        } else {
            this.FormControlMetaDataService.setMetadata(this.formControl, this.control.field, this.control.options);
            this.selectDefault();
        }
    }

    selectDefault() {
        if (this.control && !this.formControl?.value?.toString()?.length) {
            const selectedOptions = this.control.options.find(opt => !!opt.isChecked);
            if (selectedOptions) {
                this.formControl.setValue(selectedOptions.key);
                this.control.value = selectedOptions.key;
            }
        }
    }

    onSelectionChange(control: ISelectControl): void {
        control.options.forEach(element => {
            element.isChecked = control.value === element.key ? true : false;
        });
        this.selectionChange.emit(control);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

