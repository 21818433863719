import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // Define an EventEmitter to pass parameters

 

  private userName: string = '';
  passParamsEvent = new EventEmitter<any>();
  triggerNavigationEvent = new EventEmitter<void>();

  constructor() {}

  // Define a method to set the parameters
  setUserName(userName: string) {
    this.userName = userName;
  }
  // Define a method to get the parameters
  getUserName(): string {
    return this.userName;
  }
  

  clearLocalStorageExcept(exceptionsArr) {
    Object.keys(localStorage).forEach(key => {
      const index = exceptionsArr.findIndex(exceptionStr => exceptionStr === key);
      if (index >= 0) {
        return;
      } else {
        localStorage.removeItem(key);
      }
    });
  }

}
