import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { map, Observable, of, ReplaySubject, tap } from 'rxjs';
import { DatafactoryService } from '../data-factory/data.factory';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RequestParams } from '../data-factory/request-param';

const navigationURLS = {
    GetNavigation: environment.webApi + 'Home/GetNavigation',
    GetModuleTabs: environment.webApi + 'Home/GetModuleTabs'
}
@Injectable({ providedIn: 'root' })

export class NavigationService {
    public _navigation: ReplaySubject<any> = new ReplaySubject<any>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private dataFactoryService: DatafactoryService, private requestParams: RequestParams) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<any> {
        /* const navigationArr = this.constructNavigationItems(Navigationdata);
        this._navigation.next(navigationArr)
        return of(navigationArr) */
        return this.dataFactoryService.GetMethod(navigationURLS.GetNavigation).pipe(
            map(data => {
                if (data[0].response === 'success') {
                    const navData = JSON.parse(data[0].responseData);
                    localStorage.setItem('navData', JSON.stringify(navData))
                    const navigationArr = this.constructNavigationItems(navData);
                    this._navigation.next(navigationArr);
                    return navigationArr;
                }
            }),
        );
    }

     /**
     * Get Module wise Tabs list
     */
     GetRespectiveModuleTabs(moduleName: string): Observable<any> {
        return this.dataFactoryService.postMethod(navigationURLS.GetModuleTabs, this.requestParams.ConstructRequest({moduleName})).pipe(
            map(data => {
                if (data?.length && data[0].responseCode === 1 && data[0].response === 'success') {
                    const tabsData = JSON.parse(data[0].responseData);
                    return tabsData;
                }
            }),
        );
    }

    constructNavigationItems(navData) {
        const navArr = [];
        navData?.forEach(item => {
            const navObj = {
                id: item.NavigationId,
                title: item.NavigationName,
                type: item.ChildMenuItems?.length ? 'collapsable' : 'basic',
                icon: item.NavigationIcon,
                link: item.NavigationLink,
                children: this.constructNavigationItems(item.ChildMenuItems)
            }
            navArr.push(navObj);
        })
        return navArr;
    }
}
