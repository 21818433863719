import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { INumberControl } from './number-control.interface';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgIf
  ]
})
export class NumberComponent {
  @Input()control : INumberControl | null = null;

  @Input()formControl : FormControl | null = null;
  @Input()formGroup : FormGroup | null = null;

  @Input()controlIndex !: number;
}
