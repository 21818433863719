<ng-container *ngIf="control as control">
    <ng-container [ngSwitch]="control.style">
        <ng-container *ngSwitchCase="'mat-button'">
            <button mat-button 
                    [color]="control.color"
                    [id]="control.id"
                    [class]="control.customClass"
                    [type]="control.type"
                    [matTooltip]="control.tooltip"
                    (click)="buttonClick(control.type)"
                    >{{control.label}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'mat-raised-button'">
            <button mat-raised-button 
                    [color]="control.color"
                    [id]="control.id"
                    [class]="control.customClass"
                    [type]="control.type"
                    [matTooltip]="control.tooltip"
                    (click)="buttonClick(control.type)"
                    extended 
                    >{{control.label}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'mat-stroked-button'">
            <button mat-stroked-button 
                    [color]="control.color"
                    [id]="control.id"
                    [class]="control.customClass"
                    [type]="control.type"
                    [matTooltip]="control.tooltip"
                    (click)="buttonClick(control.type)"
                    >{{control.label}}</button>
        </ng-container>
        <ng-container *ngSwitchCase="'mat-flat-button'">
            <button mat-flat-button 
                    [color]="control.color"
                    [id]="control.id"
                    [class]="control.customClass"
                    [type]="control.type"
                    [matTooltip]="control.tooltip"
                    (click)="buttonClick(control.type)">
                {{control.label}}
            </button>
        </ng-container>
    </ng-container>  
</ng-container>