import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { DatafactoryService } from '../data-factory/data.factory';
import { RequestParams } from '../data-factory/request-param';
import { UserStatus } from '../user/user-status.enum';
import { Patient } from './patient.model';
import { UserActivityLogModel } from './user-activity-log.model';
import { GUID_EMPTY } from '../constants/app.constants';

const patientSearchApis = {
    SaveForm: environment.webApi + 'Forms/SaveFormData',
    GetListOfAllUsers: environment.webApi + 'User/GetListOfAllUsers',
    GetAdditionalDetails: environment.webApi + 'User/GetAdditionalDetails',
    GetUserDetailsById: environment.webApi + 'User/GetUserDetailsById',
    GetUserActivityLog: environment.webApi + 'User/GetUserActivityLog'
};

@Injectable({
    providedIn: 'root',
})
export class PatientSearchApiService {
    patientSearchIsCollapsedFilter = true;
    consultSearchIsCollapsedFilter = true;
    enrolmentSearchIsCollapsedFilter = true;

    patientSearchfilter = [
        { targetField: 'searchText', value: '', inputType: 'searchText' },
        { section: 1, row: 0, column: 0, name: 'Facility Group', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'OrgGroupwithAll', targetField: 'OrgGroupid', options: [], value: 0, defaultValue: 0 },
        { section: 1, row: 0, column: 1, name: 'Facility', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'FacilitiesbyGroupwithAll', targetField: 'selectedOrgGuidlist', refFields: "OrgGroupid", value: 0, defaultValue: [GUID_EMPTY], multiple: true, fetchOptionsOnReset: true },
        { section: 1, row: 0, column: 2, name: 'Date of birth', inputType: 'datePicker', desc: '', showStatus: false, targetField: 'DOB', options: [], lookupTypeReq: '' },
        //   { name: 'Age', inputType: 'text', desc: '', showStatus: false, targetField: 'patientAge', lookupTypeReq: '' },
        { section: 2, row: 0, column: 0, name: 'Gender', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'gender', targetField: 'GenderId', options: [], multiple: true },
        { section: 2, row: 0, column: 1, name: 'Palliative', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: '', targetField: 'IsPalliative', options: [{ key: 1, "lable": "Yes" }, { "key": 0, "lable": "No" }], multiple: true },
        { section: 2, row: 0, column: 2, name: 'Enrolment Status', inputType: 'Select', desc: '', showStatus: false, targetField: 'EnrolmentStatusId', options: [], lookupTypeReq: 'EnrolmentStatus', multiple: true }
    ];

    consultSearchfilter = [
        { targetField: 'searchText', value: '', inputType: 'searchText' },
        { section: 1, row: 0, column: 0, name: 'Facility Group', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'OrgGroupwithAll', targetField: 'OrgGroupid', options: [], value: 0, defaultValue: 0 },
        { section: 1, row: 0, column: 1, name: 'Facility', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'FacilitiesbyGroupwithAll', targetField: 'selectedOrgGuidlist', refFields: "OrgGroupid", value: 0, defaultValue: [GUID_EMPTY], multiple: true, fetchOptionsOnReset: true },
        { section: 1, row: 0, column: 2, name: 'Consult By', inputType: 'Select', desc: '', showStatus: false, targetField: 'ConsultBy', options: [], lookupTypeReq: 'ConsultBy', value: null, multiple: true },
        {
            section: 2, row: 0, column: 0, name: 'Select Date Type', inputType: 'Select', desc: '', showStatus: false, dateTypeSwitcherId: 1,
            options: [
                { lable: "Consult Search", key: "consultSearch", dateFields: ['ConsultDateFrom', 'ConsultDateTo'] }
            ],
            lookupTypeReq: '', value: 'consultSearch', defaultValue: 'consultSearch'
        },
        { section: 2, row: 0, column: 1, name: 'From Date', inputType: 'datePicker', desc: '', showStatus: false, targetField: 'ConsultDateFrom', options: [], lookupTypeReq: '', dateTypeSwitcherFor: 1, placeholderName: 'dd/mm/yyyy' },
        { section: 2, row: 0, column: 2, name: 'To Date', inputType: 'datePicker', desc: '', showStatus: false, targetField: 'ConsultDateTo', options: [], lookupTypeReq: '', dateTypeSwitcherFor: 1, placeholderName: 'dd/mm/yyyy' },
        { section: 3, row: 0, column: 0, name: 'Gender', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'gender', targetField: 'GenderId', options: [], multiple: true },
        { section: 3, row: 0, column: 1, name: 'Palliative', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: '', targetField: 'IsPalliative', options: [{ key: 1, "lable": "Yes" }, { "key": 0, "lable": "No" }], multiple: true },
        { section: 3, row: 0, column: 2, name: 'Enrolment Status', inputType: 'Select', desc: '', showStatus: false, targetField: 'EnrolmentStatusId', options: [], lookupTypeReq: 'EnrolmentStatus', multiple: true }
    ];

    enrolmentSearchfilter = [
        { targetField: 'searchText', value: '', inputType: 'searchText' },
        { section: 1, row: 0, column: 0, name: 'Facility Group', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'OrgGroupwithAll', targetField: 'OrgGroupid', options: [], value: 0, defaultValue: 0 },
        { section: 1, row: 0, column: 1, name: 'Facility', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'FacilitiesbyGroupwithAll', targetField: 'selectedOrgGuidlist', refFields: "OrgGroupid", value: 0, defaultValue: [GUID_EMPTY], multiple: true, fetchOptionsOnReset: true },
        { section: 1, row: 0, column: 2, name: 'Date of birth', inputType: 'datePicker', desc: '', showStatus: false, targetField: 'DOB', options: [], lookupTypeReq: '' },
        {
            section: 2, row: 0, column: 0, name: 'Select Date Type', inputType: 'Select', desc: '', showStatus: false, dateTypeSwitcherId: 1,
            options: [
                { lable: "Enrolment Search", key: "enrolmentSearch", dateFields: ['EnrolmentDateFrom', 'EnrolmentDateTo'] }
            ],
            lookupTypeReq: '', value: 'enrolmentSearch', defaultValue: 'enrolmentSearch'
        },
        { section: 2, row: 0, column: 1, name: 'From Date', inputType: 'datePicker', desc: '', showStatus: false, targetField: 'EnrolmentDateFrom', options: [], lookupTypeReq: '', dateTypeSwitcherFor: 1, placeholderName: 'dd/mm/yyyy' },
        { section: 2, row: 0, column: 2, name: 'To Date', inputType: 'datePicker', desc: '', showStatus: false, targetField: 'EnrolmentDateTo', options: [], lookupTypeReq: '', dateTypeSwitcherFor: 1, placeholderName: 'dd/mm/yyyy' },
        //   { name: 'Age', inputType: 'Select', desc: '', showStatus: false, targetField: 'patientAge', options: this.generateAgeOptions(60, 120), lookupTypeReq: '' },
        { section: 3, row: 0, column: 0, name: 'Enroled By', inputType: 'Select', desc: '', showStatus: false, targetField: 'EnroledBy', refFields: "selectedOrgGuidlist", options: [], lookupTypeReq: 'EnroledBy', multiple: true, value: null, noPreselect: true, fetchOptionsOnReset: true },
        { section: 3, row: 0, column: 1, name: 'Gender', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: 'gender', targetField: 'GenderId', options: [], multiple: true },
        { section: 3, row: 0, column: 2, name: 'Palliative', inputType: 'Select', desc: '', showStatus: false, lookupTypeReq: '', targetField: 'IsPalliative', options: [{ key: 1, "lable": "Yes" }, { "key": 0, "lable": "No" }], multiple: true },
        { section: 3, row: 0, column: 3, name: 'Enrolment Status', inputType: 'Select', desc: '', showStatus: false, targetField: 'EnrolmentStatusId', options: [], lookupTypeReq: 'EnrolmentStatus', multiple: true }
    ];
    constructor(private dataFactoryService: DatafactoryService, private requestParams: RequestParams) { }

    generateAgeOptions(start, end) {
        const options = [];
        for (let i = start; i <= end; i += 10) {
            const value = { from: i, to: i + 9 }
            const option = `${i} to ${i + 9}`;
            options.push({ key: value, lable: option });
        }
        return options;
    }

    getFilterListForPatientSearch(): Observable<any> {
        return of(this.patientSearchfilter);
    }

    getFilterListForConsultSearch(): Observable<any> {
        return of(this.consultSearchfilter);
    }

    getFilterListForEnrollmentSearch(): Observable<any> {
        return of(this.enrolmentSearchfilter);
    }

    getPatientDetailsForm(): Observable<any> {
        // return json from assets
        return this.dataFactoryService.getJSON(
            'assets/data/forms/patients/patient-details-form.json'
        );
    }

    getPatientFacilityForm(): Observable<any> {
        // return json from assets
        return this.dataFactoryService.getJSON(
            'assets/data/forms/patients/facility-details-form.json'
        );
    }

    getPatientFamiliyDetailsForm(): Observable<any> {
        // return json from assets
        return this.dataFactoryService.getJSON(
            'assets/data/forms/patients/patient-family-details-form.json'
        );
    }

    savePatientForm(params: {
        userGuid: string,
        selectedOrgGuid: string,
        FormName: string,
        FormTypeId: string,
        FormJsonData: string
    }) {
        return this.dataFactoryService.postMethod(patientSearchApis.SaveForm, this.requestParams.ConstructRequest(params));
    }

    GetAdditionalDetails(params: {
        userGuid: string,
        selectedOrgGuid: string
    }) {
        return this.dataFactoryService.postMethod(patientSearchApis.GetAdditionalDetails, this.requestParams.ConstructRequest(params));
    }

    GetUserDetailsById(params: {
        userGuid: string,
        selectedOrgGuid: string
    }) {
        return this.dataFactoryService.postMethod(patientSearchApis.GetUserDetailsById, this.requestParams.ConstructRequest(params))
            .pipe(map(res => {
                let userDetails = [];
                if (res?.length && res[0].responseCode === 1) {
                    userDetails = JSON.parse(res[0].responseData);
                }
                return userDetails?.length ? userDetails[0] : userDetails;
            }));
    }

    GetListOfAllUsers(params: {
        selectedOrgGuid: string,
        searchJSON: string,
        pageNumber: string
    }): Observable<any[]> {
        return this.dataFactoryService.postMethod(patientSearchApis.GetListOfAllUsers, this.requestParams.ConstructRequest(params))
            .pipe(map(res => {
                let patients = [];
                if (res?.length && res[0].responseCode === 1) {
                    patients = JSON.parse(res[0].responseData);
                }
                return patients;
            }));
    }

    GetUserActivityLog(params: {
        moduleId: string
    }): Observable<UserActivityLogModel[]> {
        return this.dataFactoryService.postMethod(patientSearchApis.GetUserActivityLog, this.requestParams.ConstructRequest(params))
            .pipe(map(res => {
                let logs = [];
                if (res?.length && res[0].responseCode === 1) {
                    logs = JSON.parse(res[0].responseData);
                }
                return logs;
            }));
    }

}
