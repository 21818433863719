<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">
        <mat-form-field class="mat-form-field {{control.customClass}}" style="width: 100%">
            <mat-label *ngIf="control.label?.length">{{control.label}}</mat-label>
            <mat-select [placeholder]="control.label" [id]="control.id" [matTooltip]="control.tooltip"
                [formControl]="formControl" (change)="onSelectionChange(control)"
                [required]="control.isRequired">
                <mat-option *ngFor="let option of control.options" [value]="option.key" [selected]="control.isChecked">
                    {{ option.lable }}
                </mat-option>
            </mat-select>
            <!-- <mat-error class="mat-error" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
                <ng-container class="mat-error" *ngIf="formControl?.errors?.required">
                    {{control.label}} is required
                </ng-container>
            </mat-error> -->
            <!-- <mat-error class="mat-error" *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
                <ng-container class="mat-error" *ngIf="formControl?.errors?.refFields">
                    {{formControl.get(control.refFields)?.label}} is required
                </ng-container>
            </mat-error> -->
        </mat-form-field>
    </ng-container>
</ng-container>