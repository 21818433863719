import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'dd/MM/yyyy',
    },
    display: {
      dateInput: 'dd/MM/yyyy',
      monthYearLabel: 'MMM yyyy',
      dateA11yLabel: 'dd/MM/yyyy',
      monthYearA11yLabel: 'MMM yyyy',
    },
  };

export const MY_DATE_FORMATS_PROVIDER = {
  provide: MAT_DATE_FORMATS,
  useValue: MY_DATE_FORMATS,
};

export const LUXON_DATE_ADAPTER_FACTORY_PROVIDER = {
  provide: DateAdapter,
  useClass: LuxonDateAdapter,
  deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
};

export const LUXON_DATE_FORMATS_PROVIDER = {
  provide: MAT_LUXON_DATE_FORMATS,
  useValue: MY_DATE_FORMATS,
};