import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { BreadcrumbService } from 'app/core/breadcrumb/breadcrumb.service';
import { IBreadcrumb } from 'app/core/breadcrumb/IBreadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [MatIconModule, RouterLink, NgForOf, NgIf]
})
export class BreadcrumbComponent {
  breadcrumbs$ = this.breadcrumbService.getBreadcrumbs();
  breadCrumbs: IBreadcrumb[];

  constructor(private breadcrumbService: BreadcrumbService, private router: Router) {}

  ngOnInit(): void {
    this.breadcrumbs$.subscribe(breadCrumbs => {
      this.breadCrumbs = breadCrumbs;
    });
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url, {state: history.state})
  }
}
