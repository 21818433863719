<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">
        <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}} hidden" style="width: 100%">
            <mat-label>{{control.label}}</mat-label>
            <input matInput textTrim [type]="control.inputType ? control.inputType : 'text'" [id]="control.id"
                pattern="{{control.pattern}}" [placeholder]="control.label" [formControl]="formControl"
                (change)="onChange(control)" [matTooltip]="control.tooltip" [maxlength]="control.maxlength"
                [minlength]="control.minlength" [required]="control.isRequired"
                [disabled]="control.isDisabled ? true : false">
            <mat-error class="mat-error" *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched)">
                <!-- <ng-container class="mat-error" *ngIf="formControl?.errors?.required">
                    {{control.label}} is required
                </ng-container> -->
                <ng-container class="mat-error" *ngIf="formControl?.errors?.minlength">
                    {{control.label}} must be at least {{control.minlength}} characters long.
                </ng-container>
                <ng-container class="mat-error" *ngIf="formControl?.errors?.pattern">
                    {{control.customErrorMsg}}
                </ng-container>
                <ng-container class="mat-error" *ngIf="formControl?.errors?.compareWith">
                    {{control.compareWithMsg}}
                </ng-container>
            </mat-error>
        </mat-form-field>
        <a class="flex items-center text-[#008080]" href="Javascript:void(0)" [attr.href]="constructedUrl" target="_blank"> <mat-icon class="mr-2 text-[#008080]">location_on</mat-icon> Google Location Pin</a>
    </ng-container>