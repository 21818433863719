   <!-- Header -->
   <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between px-4 py-2 sm:py-2 sm:px-4 border-b bg-card bg-white">
    <div class="flex-1 min-w-0">
        <!-- Breadcrumbs -->
        <div>
            <div class="hidden sm:flex flex-wrap items-center font-medium">
                <div>
                    <a class="whitespace-nowrap text-primary-500 cursor-pointer" [routerLink]="'/'">Home</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap" *ngFor="let breadCrumb of breadCrumbs">
                    <mat-icon
                        class="icon-size-5 text-secondary"
                        [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
                    
                    <a *ngIf="breadCrumb.cannotNavigateBack" class="ml-1 text-secondary">{{breadCrumb.label}}</a>

                    <a *ngIf="!breadCrumb.cannotNavigateBack" class="ml-1 text-secondary" [class.cursor-pointer]="!breadCrumb.isActive" 
                        [class.text-primary-500]="!breadCrumb.isActive" (click)="navigateTo(breadCrumb.url)">{{breadCrumb.label}}</a>
                </div>
            </div>
            <div class="flex sm:hidden">
                <a
                    class="inline-flex items-center -ml-1.5 text-secondary font-medium"
                    [routerLink]="'./..'">
                    <mat-icon
                        class="icon-size-5 text-secondary"
                        [svgIcon]="'heroicons_solid:chevron-left'"></mat-icon>
                    <span class="ml-1">Back</span>
                </a>
            </div>
        </div>
        <!-- Title -->
        <!-- <div>
            <h2 class="text-lg font-extrabold tracking-tight leading-7 sm:leading-10 truncate" *ngIf="breadCrumbs?.length">
                {{breadCrumbs[breadCrumbs.length-1]?.label}}
            </h2>
        </div> -->
    </div>
</div>