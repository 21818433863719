import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IRadioButtonControl } from './radio-button-control.interface';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { LookuplistApiService } from 'app/core/lookuplist/lookuplist.api.service';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
    MatRadioModule,
    NgClass
  ]
})
export class RadioButtonComponent implements OnChanges {
  @Input()control : IRadioButtonControl | null = null;

  @Input()formControl : FormControl | null = null;
  @Input()formGroup : FormGroup | null = null;

  @Input()controlIndex !: number;
  @Output() selectionChange = new EventEmitter<IRadioButtonControl>();

  /**
   *
   */
  constructor(private lookupListService: LookuplistApiService) {
    this.selectDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.control.lookupTypeReq?.length) {
      this.lookupListService.GetLookupListByTpe(this.control.lookupTypeReq, '{}')
      .subscribe(options => {
          this.control.options = options || [];
          this.selectDefault();
      });

    } else {
        this.selectDefault();
    }
}

  selectDefault() {
    if (this.control && !this.formControl?.value?.toString()?.length){
      const selectedOptions = this.control.options.find(opt => !!opt.isChecked);
      if(selectedOptions) {
          this.formControl.setValue(selectedOptions.key);
          this.control.value = selectedOptions.key;
      }
    }
  }

  onSelectionChange(control: IRadioButtonControl): void {
    control.options.forEach(element => {
        element.isChecked = control.value === element.key ? true : false;
    });
    this.selectionChange.emit(control);
}

}
