<ng-container *ngIf="control as control">
    <ng-container [formGroup]="formGroup">
        <mat-form-field [appearance]="control.style" class="mat-form-field {{control.customClass}}" style="width: 100%">
            <mat-label>{{control.label}}</mat-label>
            <input matInput textTrim [type]="control.inputType ? control.inputType : 'text'" [id]="control.id"
                pattern="{{control.pattern}}" [placeholder]="control.label" [formControl]="formControl"
                (change)="onChange(control)" [matTooltip]="control.tooltip" [maxlength]="control.maxlength"
                [minlength]="control.minlength" [required]="control.isRequired"
                [disabled]="control.isDisabled ? true : false" autocomplete="off">
            <mat-error class="mat-error [w-100%]"
                *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched) && formControl?.errors?.compareWith">
                <ng-container class="mat-error" *ngIf="formControl?.errors?.compareWith">
                    {{control.compareWithMsg}}
                </ng-container>
            </mat-error>
            <mat-error class="mat-error [w-100%]"
                *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched) && formControl?.errors?.minlength">
                <ng-container class="mat-error" *ngIf="formControl?.errors?.minlength">
                    {{control.label}} must be at least {{control.minlength}} characters long.
                </ng-container>
            </mat-error>
            <mat-error class="mat-error [w-100%]"
                *ngIf="formControl?.invalid && (formControl?.dirty || formControl?.touched) && formControl?.errors?.pattern">
                <ng-container class="mat-error" *ngIf="formControl?.errors?.pattern">
                    {{control.customErrorMsg}}
                </ng-container>
            </mat-error>
        </mat-form-field>
    </ng-container>