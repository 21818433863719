import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DatafactoryService } from '../data-factory/data.factory';
import { tap } from 'rxjs';
import { GUID_EMPTY } from '../constants/app.constants';
import { RequestParams } from '../data-factory/request-param';
import { DocumentModel } from 'app/layout/common/documents-list/document.model';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';

export const DocumentsUrl = {
  uploadDocs: environment.webApi + `Document/UploadUserDocs`,
  CreateNewFolder: environment.webApi + `Document/CreateNewFolder`,
  RenameFileOrFolder: environment.webApi + `Document/RenameFileOrFolder`,
  DeleteFileOrFolder: environment.webApi + `Document/DeleteFileOrFolder`,
  GetFileorFolder: environment.webApi + `Document/GetFileorFolder`,
  downloadDocument: (fileName: string, selectedOrgGuid: string, orgUserGuid: string, relativePath: string) => environment.webApi + `Document/DownloadUserDocs?fileName=${fileName}&selectedOrgGuid=${selectedOrgGuid}&orgUserGuid=${orgUserGuid}&relativePath=${relativePath}`,
  uploadProfileImage: environment.webApi + `Document/UploadProfileImage`,
  downloadProfileImage: (fileName: string, selectedOrgGuid: string, orgUserGuid: string) => environment.webApi + `Document/DownloadProfileImage?fileName=${fileName}&selectedOrgGuid=${selectedOrgGuid}&orgUserGuid=${orgUserGuid}`,
  deleteProfileImage: (fileName: string, selectedOrgGuid: string, orgUserGuid: string) => environment.webApi + `Document/DeleteProfileImage?fileName=${fileName}&selectedOrgGuid=${selectedOrgGuid}&orgUserGuid=${orgUserGuid}`,
  getUserDocumentUrl: (fileName: string, selectedOrgGuid: string, orgUserGuid: string, actualFilePath: string) => environment.webApi + `Document/GetUserDocumentURL?fileName=${fileName}&selectedOrgGuid=${selectedOrgGuid}&orgUserGuid=${orgUserGuid}&relativePath=${actualFilePath}`,
  GetSASTokenForProfilePictures: (selectedOrgGuid: string) => environment.webApi + `Document/GetSASTokenForProfilePictures?selectedOrgGuid=${selectedOrgGuid}`
}

@Injectable({
  providedIn: 'root'
})
export class DocumentsUploadService {

  constructor(private dataFactory: DatafactoryService, private requestParams: RequestParams, private alert: SweetAlert) { }

  isDocIframeViewable(file: DocumentModel) {
    const iframeViewableDocTypes = "pdf, doc, docx, xls, xlsx, ppt, pptx";
    return iframeViewableDocTypes.split(",").some(type => type?.trim() === file.type?.toLowerCase());
  }

  uploadProfileImage(selectedOrgGuid: string, userGuid: string, file) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('selectedOrgGuid', selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY);
    formData.append('orgUserGuid', userGuid);

    return this.dataFactory.uploadFiles(DocumentsUrl.uploadProfileImage, formData).pipe(
      tap(res => 
        (response) => {
          console.log('File uploaded successfully:', res);
      })
    );
  }

  downloadProfileImage(selectedOrgGuid: string, userGuid: string, fileName: string) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.downloadFile(DocumentsUrl.downloadProfileImage(fileName, selectedOrgGuid, userGuid)).pipe(
      tap(res => 
        (response) => {
          console.log('File downloaded successfully:', res);
      })
    );
  }

  deleteProfileImage(selectedOrgGuid: string, userGuid: string, fileName: string) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.GetMethod(DocumentsUrl.deleteProfileImage(fileName, selectedOrgGuid, userGuid)).pipe(
      tap(res => 
        (response) => {
          console.log('File deleted successfully:', res);
      })
    );
  }

  downloadDocument(selectedOrgGuid: string, userGuid: string, fileName: string, actualFilePath: string) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.downloadFile(DocumentsUrl.downloadDocument(fileName, selectedOrgGuid, userGuid, actualFilePath)).pipe(
      tap(res => 
        (response) => {
          console.log('File downloaded successfully:', res);
      })
    );
  }

  uploadDocuments(selectedOrgGuid: string, userGuid: string, files, relativePath: string) {
    const formData = new FormData();
    for (let file of files) {
      formData.append('files', file);
    }
    
    formData.append('selectedOrgGuid', selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY);
    formData.append('orgUserGuid', userGuid);
    formData.append('relativePath', relativePath);

    return this.dataFactory.uploadFiles(DocumentsUrl.uploadDocs, formData).pipe(
      tap(res => 
        (response) => {
          console.log('File(s) uploaded successfully:', res);
      })
    );
  }

  getUserDocumentUrl(selectedOrgGuid: string, userGuid: string, fileName: string, actualFilePath) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.GetMethod(DocumentsUrl.getUserDocumentUrl(fileName, selectedOrgGuid, userGuid, actualFilePath)).pipe(
      tap(res => 
        (response) => {
          console.log('File downloaded successfully:', res);
      })
    );
  }

  GetSASTokenForProfilePictures(selectedOrgGuid: string) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.GetMethod(DocumentsUrl.GetSASTokenForProfilePictures(selectedOrgGuid)).
      subscribe((res: any) => {
          console.log('SAS tokens received successfully:', res);
          if (res?.responseData) {
            console.log(JSON.parse(res.responseData));
          }
      });
  }

  createNewFolder(selectedOrgGuid: string, userGuid: string, actualPath: string, folderName: string) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.postMethod(DocumentsUrl.CreateNewFolder, this.requestParams.ConstructRequest({selectedOrgGuid, orgUserGuid: userGuid, actualPath, folderName})).pipe(
      tap(res =>  {
          console.log('Folder created successfully:', res);
          if (res?.length && res[0].responseCode === 1 ) {
            this.alert.showToast("success", "Folder created successfully!", 3600)
          }
      })
    );
  }

  renameFileOrFolder(selectedOrgGuid: string, userGuid: string, uniqueFileOrFolderName: string, newFileOrFolderName: string, relativePath: string, isFolder: number) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.postMethod(DocumentsUrl.RenameFileOrFolder, this.requestParams.ConstructRequest({selectedOrgGuid, orgUserGuid: userGuid, uniqueFileOrFolderName, newFileOrFolderName, relativePath, isFolder: isFolder?.toString()})).pipe(
      tap(res => {
          console.log('Folder renamed successfully:', res);
          if (res?.length && res[0].responseCode === 1 ) {
            this.alert.showToast("success", `${isFolder ? 'Folder' : 'File'} renamed successfully!`, 3600)
          }
      })
    );
  }

  deleteFileOrFolder(selectedOrgGuid: string, userGuid: string, uniqueFileOrFolderName: string, relativePath: string, isFolder: number) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.postMethod(DocumentsUrl.DeleteFileOrFolder, this.requestParams.ConstructRequest({selectedOrgGuid, orgUserGuid: userGuid, uniqueFileOrFolderName, relativePath, isFolder: isFolder?.toString()})).pipe(
      tap(res =>  {
          console.log('Folder created successfully:', res);
          if (res?.length && res[0].responseCode === 1 ) {
            this.alert.showToast("success", `${isFolder ? 'Folder' : 'File'} deleted successfully!`, 3600)
          }
      })
    );
  }

  getFileorFolder(selectedOrgGuid: string, userGuid: string, searchJson: string) {
    selectedOrgGuid = selectedOrgGuid ? selectedOrgGuid : GUID_EMPTY;
    return this.dataFactory.postMethod(DocumentsUrl.GetFileorFolder, this.requestParams.ConstructRequest({selectedOrgGuid, orgUserGuid: userGuid, searchJson})).pipe(
      tap(res => 
        (response) => {
          console.log('Got response successfully:', res);
      })
    );
  }

}
