import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ITextareaControl } from './textarea-control.interface';
import { TextTrimDirective } from '../textfield/text-trim.directive';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgIf,
    TextTrimDirective
  ]
})
export class TextareaComponent {
  @Output() selectionChange = new EventEmitter<ITextareaControl>();
  @Input()control : ITextareaControl | null = null;

  @Input()formControl : FormControl | null = null;
  @Input()formGroup : FormGroup | null = null;

  @Input()controlIndex !: number;

  onChange(control: ITextareaControl): void {
    this.selectionChange.emit(control);
}
}
