import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ITextFieldControl } from '../textfield/textfield-control.interface';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeInputAutomateDirective } from '@fuse/directives/time-fields/time-input-automate.directive';

@Component({
    selector: 'app-time-picker',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgIf,
        TimeInputAutomateDirective,
    ],
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent {
    @Output() selectionChange = new EventEmitter<ITextFieldControl>();
    @Input() control: ITextFieldControl | null = null;

    @Input() formControl: FormControl | null = null;
    @Input() formGroup: FormGroup | null = null;

    @Input() controlIndex!: number;

    onChange(control: ITextFieldControl): void {
      this.selectionChange.emit(control);
    }
}
