import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';
import { Location } from '@angular/common';

export const LOCAL_HOST = ['localhost:4200', 'localhost:4400'];

@Injectable({
  providedIn: 'root'
})

export class AppStateService {

  isOffline = false;

  constructor(
    private alert: SweetAlert,
    public connectionService: ConnectionService,
    public location: Location
  ) { }

  watchInternetConnection() {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isOffline = !isConnected;
      if (isConnected) {
        const elms = document.querySelectorAll('.internetConnection');
        for (let i = 0; i < elms.length; i++) {
          elms[i].remove();
        }
      } else {
        this.alert.showToast('error', 'No Internet connection!', null, 'internetConnection');
      }
    })
  }

  public setCookie(cname, cvalue, minutes, domain) {
    const d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + `domain=${domain};` + expires + ";path=/";
  }

  public getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  public getSessionStorageFromCookie() {
    const sessionStr = this.getCookie('session');
    if (sessionStr && sessionStr?.length) {
      const session = JSON.parse(sessionStr);
      Object.keys(session).forEach(key => sessionStorage.setItem(key, session[key]));
      setTimeout(() => this.deleteCookie('session'), 0);
    }
  }

  public deleteCookie(cookieName) {
    const domain = LOCAL_HOST.some(host => window.location.href.indexOf(host) >= 0) ? 'localhost' : '.' + sessionStorage.getItem('parentdomain');
    document.cookie = cookieName + "=" + `;domain=${domain};max-age=0;path=/;`;
  }

  // public navigateWithDomainChange(url, subDomain, domain) {
  //   const sessionStr = JSON.stringify(sessionStorage);
  //   if (LOCAL_HOST.some(host => window.location.href.indexOf(host) >= 0)) {
  //     if (sessionStorage) {
  //       this.setCookie("session", sessionStr, 5, 'localhost');
  //     }
  //     sessionStorage.clear();
  //     window.location.replace(`http://localhost:4300/#${url}`);
  //   } else {
  //     if (sessionStorage) {
  //       this.setCookie("session", sessionStr, 5, `.${domain}`);
  //     }
  //     sessionStorage.clear();
  //     window.location.replace(`https://${subDomain}.${domain}/#${url}`);
  //   }
  // }

  // public navigateToSchools(url, subDomain, domain) {
  //   const sessionStr = JSON.stringify(sessionStorage);
  //   if (LOCAL_HOST.some(host => window.location.href.indexOf(host) >= 0)) {
  //     if (sessionStorage) {
  //       this.setCookie("session", sessionStr, 5, "localhost");
  //     }
  //     sessionStorage.clear();
  //     window.location.replace(`http://localhost:4200/#${url}`);
  //   } else {
  //     if (sessionStorage) {
  //       this.setCookie("session", sessionStr, 5, `.${domain}`);
  //     }
  //     sessionStorage.clear();
  //     window.location.replace(`https://${subDomain}.${domain}/#${url}`);
  //   }
  // }

  // public navigateDomainOnLogin(domain: string, domainUrl: string, routerUrl: string): void {
  //   const sessionStr = JSON.stringify(sessionStorage);
  //   if (sessionStorage) {
  //     this.setCookie("session", sessionStr, 5, `.${domainUrl}`);
  //   }
  //   sessionStorage.clear();
  //   window.location.replace(`https://${domain}.${domainUrl}/#${routerUrl}`);
  // }

  // bindThemeColor() {
  //   if (this.location.path().indexOf('/sw/') !== -1) {
  //     document.documentElement.style.setProperty('--primary', sessionStorage.getItem('brandcolor'));
  //     document.body.setAttribute('color-theme', sessionStorage.getItem('brandcolor'));
  //   } else {
  //     document.documentElement.style.setProperty('--primary', '#3175bc');
  //     document.body.removeAttribute('color-theme');
  //   }
  // }
}
