import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormControlMetaDataService {

  constructor() { }

  setMetadata(control: AbstractControl, key: string, value: any): void {
    control['__meta__'] = control['__meta__'] || {};
    control['__meta__'][key] = value;
    // trigger the change
    control.updateValueAndValidity();
  }

  getMetadata(control: AbstractControl, key: string): any {
    return control['__meta__'] ? control['__meta__'][key] : undefined;
  }
}
