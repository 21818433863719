import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DynamicFormService} from './dynamic-form.service';
import {FormGroup} from '@angular/forms';
import {NgFor, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {AutocompleteComponent} from './form-controls/autocomplete/autocomplete.component';
import {ButtonComponent} from './form-controls/button/button.component';
import {CheckboxComponent} from './form-controls/checkbox/checkbox.component';
import {DatepickerComponent} from './form-controls/datepicker/datepicker.component';
import {DividerComponent} from './form-controls/divider/divider.component';
import {HeaderComponent} from './form-controls/header/header.component';
import { MultiSelectComponent } from './form-controls/multi-select/multi-select.component';
import { NumberComponent } from './form-controls/number/number.component';
import { ParagraphComponent } from './form-controls/paragraph/paragraph.component';
import { RadioButtonComponent } from './form-controls/radio-button/radio-button.component';
import { SelectComponent } from './form-controls/select/select.component';
import { TextfieldComponent } from './form-controls/textfield/textfield.component';
import { TextareaComponent } from './form-controls/textarea/textarea.component';
import { MultiSelectWithBadgeComponent } from './form-controls/multi-select-with-badge/multi-select-with-badge.component';
import { TimePickerComponent } from './form-controls/time-picker/time-picker.component';
import { AnchorLocationComponent } from './form-controls/anchor-location/anchor-location.component';

@Component({
    selector: 'app-dynamic-forms',
    templateUrl: './dynamic-forms.component.html',
    styleUrls: ['./dynamic-forms.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        AutocompleteComponent,
        ButtonComponent,
        CheckboxComponent,
        DatepickerComponent,
        DividerComponent,
        HeaderComponent,
        MultiSelectComponent,
        NumberComponent,
        ParagraphComponent,
        RadioButtonComponent,
        SelectComponent,
        TextfieldComponent,
        TextareaComponent,
        MultiSelectWithBadgeComponent,
        TimePickerComponent,
        AnchorLocationComponent
    ]
})
export class DynamicFormsComponent implements OnChanges {
    @Input() formJSON;
    @Input() valueObj;
    @Output() formInitializedEmt = new EventEmitter();

    formContainer = [];
    fg : FormGroup;
    formInit: boolean;

    constructor(private dynamicFormService : DynamicFormService) {
    }

    resetComponent() {
        this.formInit = false;
        this.formJSON = [];
        this.valueObj = null;
        if (this.fg.controls) {
            this.fg.enable();
            this.fg.reset();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this.formJSON?.length) {
            this.createForm();
        }
    }

    /* The `createForm` method in the `DynamicFormsComponent` class is responsible for creating a form
    group using the `DynamicFormService` based on the provided `formJSON` data. Here is a breakdown
    of what the `createForm` method is doing: */
    createForm() {
        this.fg = this.dynamicFormService.createForm(this.formJSON);
        this.formJSON.forEach(control => {
            // for select we will have the listeners with in the select component 
            if (control.controlType !== 'select' && control.controlType !== 'multi-select' && control.field) {
                if (control.refFields?.length) {
                    this.dynamicFormService.appendRefControlListeners(control.field, control.refFields);
                }
            }
        });
        setTimeout(() => {
            // assign the saved values for edit
            Object.keys(this.valueObj || {}).forEach( key => {
                if (key && this.fg.controls[key] && !!this.valueObj[key]?.toString()) {
                    this.fg.controls[key].setValue(this.valueObj[key]);
                }
            });
        }, 0)
        this.formInit = true;
        this.formInitializedEmt.emit(this.fg);
    }

    // A method to mark all form controls as dirty
  markAllControlsAsDirty(): void {
    Object.keys(this.fg.controls).forEach((key) => {
      const control = this.fg.get(key);
      control.markAsTouched();
    });
  }

}

