import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { IFormControl } from './form-control.interface';
import { Observable, combineLatest, startWith } from 'rxjs';

export const /* `serializeValidators` is a function that takes an object representing control
properties as input and returns an array of ValidatorFn functions based on those
properties. It checks the properties of the control object such as `isRequired`,
`pattern`, `maxlength`, `minlength`, and `compareWith`, and adds corresponding
validator functions from Angular's Validators module to the array. The `compareWith`
property is used to compare the value of the current control with another control's
value to perform validation. The function then returns the array of validator functions
that can be applied to form controls in Angular reactive forms. */
serializeValidators = (controlObj: any): ValidatorFn[] => {
    const validators: ValidatorFn[] = [];
    if (controlObj.isRequired) {
        validators.push(Validators.required);
    }
    if (controlObj.pattern) {
        validators.push(Validators.pattern(new RegExp(controlObj.pattern))); // will display msg at actual control comp html
    }
    if (controlObj.maxlength) {
        validators.push(Validators.maxLength(controlObj.maxlength));
    }
    if (controlObj.minlength) {
        validators.push(Validators.minLength(controlObj.minlength));
    }
    if (controlObj.compareWith) {
        const compareValidator: ValidatorFn = (control: AbstractControl) => {
            const passwordControl = control.parent?.get(controlObj.compareWith); // For example : 'password' is the name of the password field (confirmPassword an another)
            if (passwordControl && passwordControl.value !== control.value) {
                return { compareWith: true }; // Validation error if values don't match
            }

            return null; // Validation passes if values match
        };

        validators.push(compareValidator);
    }

    return validators;
};

// This is used for all input fields except dropdowns.
export const /* `referenceFieldsChangeListenerForAppending` is a function that listens for changes in
the values of reference fields specified in the `fieldsRef` array. When any of the
reference fields change, the function updates the value of the `control` FormControl
based on the values of the reference fields. */
referenceFieldsChangeListenerForAppending = (
    fieldsRef: string[],
    control: FormControl,
    formControlMetaDataService: any // TODO: Create an interface for form control meta data service
) => {
    if (fieldsRef?.length) {
        // Array of observables of each reference control value changes
        const refControls$: Observable<any>[] = fieldsRef.map(
            (ref) => control.parent.controls[ref?.trim()].valueChanges.pipe(startWith(''))
        );
        // subscribe to all the above created observables
        combineLatest(refControls$).subscribe((refCtrlValues) => {
            const refValue = refCtrlValues.filter(v => v?.toString()?.length).join(' ');
            const fieldRefMappedvalues = fieldsRef.map(val => {
                const refCtrl = control.parent.controls[val?.trim()];
                const refOptions = formControlMetaDataService.getMetadata(refCtrl, getControlName(control.parent as FormGroup, refCtrl));
                if (refCtrl?.value && refOptions) {
                    return refOptions?.find(opt => opt.key === refCtrl.value)?.lable;
                } 
                return refCtrl?.value;
                
            })
            const mappedValues = [];
            fieldRefMappedvalues?.forEach(val => {
                if (val) {
                    mappedValues.push(val)
                }
            })
            control.setValue(mappedValues?.length > 1 ? mappedValues?.join('-') : mappedValues?.join(''));
        });
    }
};

export const getControlName = (formGroup: FormGroup, control: FormControl): string | null => {
    for (const key in formGroup.controls) {
        if (formGroup.controls[key] === control) {
            return key;
        }
    }
    return null;
}

// This can be used by all the types of fields.
export const /* `getRespectiveControlValueChangeListener` is a function that creates an array of
observables for each reference control specified in the `fieldsRef` array. These
observables listen for changes in the values of the reference controls. The function
then returns this array of observables, which can be subscribed to in order to track
changes in the values of the reference controls and take appropriate actions based on
those changes. */
getRespectiveControlValueChangeListener = (
    fieldsRef: string[],
    control: FormControl
) => {
    if (fieldsRef?.length) {
        // Array of observables of each reference control value changes
        const refControls$: Observable<any>[] = fieldsRef.map(
            (ref) => control.parent.controls[ref?.trim()].valueChanges.pipe(startWith(''))
        );
        // send all the above created observables
        return refControls$;
    }
};
