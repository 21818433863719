import { Component, Input } from '@angular/core';
import { IFormControlOption } from '../form-control.interface';
import { IAutoCompleteControl } from './autocomplete-control.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormService } from '../../dynamic-form.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  imports: [MatAutocompleteModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatTooltipModule, NgFor, NgIf]
})
export class AutocompleteComponent {
  // constants: any = Constants;
  filteredOptions!: IFormControlOption[] | undefined;

  @Input() control: IAutoCompleteControl | null = null;
  @Input() formControl: FormControl | null = null;
  @Input() formGroup: FormGroup | null = null;
  @Input() controlIndex!: number;

  constructor(private dynamicFormService: DynamicFormService){
    
  }

  filter(control: IAutoCompleteControl): IFormControlOption[] | undefined {
    if (control.value) {
        const filterValue = control.value.toLowerCase();
        control.options.forEach(element => {
            element.isChecked = control.value === element.key ? true : false;
        });
        return this.control?.options.filter(option => option.key.toLowerCase().startsWith(filterValue));
    }
    return this.control?.options;
}

}
