<div class="flex flex-wrap">
    <ng-container *ngFor="let control of formJSON; let i = index">
        <ng-container>
            <ng-container [ngSwitch]="control.controlType">
                <!-- Autocomplete -->
                <ng-container *ngSwitchCase="'autocomplete'">
                    <app-autocomplete class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]">
                    </app-autocomplete>
                </ng-container>
                <!-- Button -->
                <ng-container *ngSwitchCase="'button'">
                    <app-button class="{{control.column}}" [control]="control"></app-button>
                </ng-container>
                <!-- checkbox -->
                <ng-container *ngSwitchCase="'checkbox'">
                    <app-checkbox class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]"></app-checkbox>
                </ng-container>
                <!-- Datep[icker] -->
                <ng-container *ngSwitchCase="'datepicker'">
                    <app-datepicker class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]"></app-datepicker>
                </ng-container>
                <!-- Divider -->
                <ng-container *ngSwitchCase="'divider'">
                    <app-divider class="{{control.column}}" [control]="control"></app-divider>
                </ng-container>
                <!-- Header -->
                <ng-container *ngSwitchCase="'header'">
                    <app-header class="{{control.column}}" [control]="control"></app-header>
                </ng-container>
                <!-- Multi-select -->
                <ng-container *ngSwitchCase="'multi-select'">
                    <app-multi-select class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]"></app-multi-select>
                </ng-container>
                <!-- Multi-select with badge -->
                <ng-container *ngSwitchCase="'multi-select-with-badge'">
                    <app-multi-select-with-badge class="{{control.column}}" [control]="control" [controlIndex]="i"
                        [formGroup]="fg" [formControl]="fg?.controls[control.field]"></app-multi-select-with-badge>
                </ng-container>
                <!-- Number -->
                <ng-container *ngSwitchCase="'number'">
                    <app-number class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]"></app-number>
                </ng-container>
                <!-- Paragraph -->
                <ng-container *ngSwitchCase="'paragraph'">
                    <app-paragraph class="{{control.column}}" [control]="control" [formControl]="fg?.controls[control.field]"></app-paragraph>
                </ng-container>
                <!-- Radio Group -->
                <ng-container *ngSwitchCase="'radio'">
                    <app-radio-button class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]"></app-radio-button>
                </ng-container>
                <!-- Select -->
                <ng-container *ngSwitchCase="'select'">
                    <app-select class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]" [class.hidden]="control.hidden"></app-select>
                </ng-container>
                <!-- Text field -->
                <!-- TO DO: Need a refactored code to add hidden logic to the parent container -->
                <ng-container *ngSwitchCase="'textfield'">
                    <app-textfield class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]" [class.hidden]="control.hidden"></app-textfield>
                </ng-container>

                <!-- Anchor Location with google map navigation -->
                <ng-container *ngSwitchCase="'anchor-location'">
                    <app-anchor-location class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]" [class.hidden]="control.hidden"></app-anchor-location>
                </ng-container>

                <!-- Time -->
                <app-time-picker *ngSwitchCase="'time'" class="{{control.column}}" [control]="control"
                    [controlIndex]="i" [formGroup]="fg" [formControl]="fg?.controls[control.field]"
                    [class.hidden]="control.hidden"></app-time-picker>

                <!-- Text area -->
                <ng-container *ngSwitchCase="'textarea'">
                    <app-textarea class="{{control.column}}" [control]="control" [controlIndex]="i" [formGroup]="fg"
                        [formControl]="fg?.controls[control.field]"></app-textarea>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>