import {NgFor, NgIf} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ICheckboxControl} from './checkbox-control.interface';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: true,
    imports: [
        MatCheckboxModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTooltipModule,
        NgFor,
        NgIf
    ]
})
export class CheckboxComponent {

    @Input()control : ICheckboxControl | null = null;

    @Input()formControl : FormControl | null = null;
    @Input()formGroup : FormGroup | null = null;

    @Input()controlIndex !: number;
    @Output() selectionChange = new EventEmitter<ICheckboxControl>();


    onSelectionChange(control: ICheckboxControl): void {
      this.selectionChange.emit(control);
  }

}

