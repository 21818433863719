import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HeaderControl } from './header-control.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase]
})
export class HeaderComponent {
  @Input() control: HeaderControl | null = null;

}
