import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IButtonControl} from './button-control.interface';
import {MatButtonModule} from '@angular/material/button';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        MatButtonModule,
        MatTooltipModule,
        NgIf
    ]
})
export class ButtonComponent {
    @Input()control : IButtonControl | null = null;

    @Output()buttonClickEvent = new EventEmitter<string>();

    buttonClick(type : string): void {
        this.buttonClickEvent.emit(type);
    }
}

