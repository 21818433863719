import { Component, HostListener, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-tool-tip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './custom-tool-tip.component.html',
  styleUrls: ['./custom-tool-tip.component.scss']
})
export class CustomToolTipComponent {
  @ViewChild('contentRef') contentRef;
  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text: string;

  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in McToolTipRenderer directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() contentTemplate: TemplateRef<any>;
  overlayReference;

  @HostListener('mouseleave', ['$event.target'])
  hide(targetElement) {
    this.overlayReference.detach();
  }

  constructor() { }
  
}
