import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IAnchorLocationControl } from './anchor-location-control.interface';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextTrimDirective } from '../textfield/text-trim.directive';
import { ITextFieldControl } from '../textfield/textfield-control.interface';
import { Subject, combineLatest, filter, takeUntil } from 'rxjs';
import { getRespectiveControlValueChangeListener } from '../validators.const';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-anchor-location',
  templateUrl: './anchor-location.component.html',
  styleUrls: ['./anchor-location.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgIf,
    TextTrimDirective,
    MatIconModule
  ]
})
export class AnchorLocationComponent implements OnChanges, OnDestroy {
  @Output() selectionChange = new EventEmitter<IAnchorLocationControl>();
  @Input() control: IAnchorLocationControl | null = null;

  @Input() formControl: FormControl | null = null;
  @Input() formGroup: FormGroup | null = null;

  @Input() controlIndex!: number;

  gLocDomain = "https://www.google.com/maps/search/?api=1&query=";
  constructedUrl: string;
  private unsubscribe: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
}


  ngOnChanges(changes: SimpleChanges): void {
    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(v => {
      if (this.formControl.value) {
        this.constructedUrl = encodeURI(this.gLocDomain + this.formControl.value);
      }
    });
  }

  onChange(control: ITextFieldControl): void {
      this.selectionChange.emit(control);
      console.log(this.formControl, this.formControl.value);
      this.constructedUrl = encodeURI(this.gLocDomain + this.formControl.value);
  }
}
