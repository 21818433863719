import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class RequestParams {
  keyvals: any;
  public ConstructRequest(Params: any): any {
    this.keyvals = [];
    for (const key in Params) {
      if (key === 'JSONData') {
        this.keyvals.push({ key: key, val: JSON.stringify(Params[key]) });
      } else {
        this.keyvals.push({ key: key, val: Params[key] });
      }
    }
    // this.keyvals.push({ key: 'orgScreeningGuid', val: sessionStorage.getItem('orgScreeningGuid') });
    // this.keyvals.push({ key: 'userGuid', val: sessionStorage.getItem('userGuid') });
    this.keyvals.push({ key: 'orgGuid', val: localStorage.getItem('TenantGuid') });
    return { Params: this.keyvals };
  }
}
