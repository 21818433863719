/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards.home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/dashboards/home',
    },
    {
        id: 'pages.patients',
        title: 'Patients',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/patients'
    },
    {
        id: 'pages.tasks',
        title: 'Tasks',
        icon: 'heroicons_outline:clipboard-document-check',
        type: 'collapsable',
        children: [
            {
                id: 'pages.tasks.task-list',
                title: 'Tasks',
                type: 'basic',
                link: '/tasks/task-list'
            },
            {
                id: 'pages.tasks.facility-task',
                title: 'Facility Tasks',
                type: 'basic',
                link: '/tasks/facility-task'
            },
            {
                id: 'pages.rounds.patient-updates',
                title: 'Patient Updates',
                type: 'basic',
                link: '/tasks/patient-updates'
            }
        ]
    },
    {
        id: 'pages.rounds',
        title: 'Rounds',
        type: 'collapsable',
        icon: 'heroicons_outline:archive-box',
        children: [
            {
                id: 'pages.rounds.search',
                title: 'Search',
                type: 'basic',
                link: '/rounds/search'
            },
            {
                id: 'pages.rounds.myrounds',
                title: 'My Rounds',
                type: 'basic',
                link: '/rounds/my-rounds'
            },
            {
                id: 'pages.rounds.newround',
                title: 'New Round',
                type: 'basic',
                link: '/rounds/new-round'
            },
            {
                id: 'pages.rounds.scheduled',
                title: 'Scheduled',
                type: 'basic',
                link: '/rounds/scheduled'
            },
            {
                id: 'pages.rounds.inprogress',
                title: 'Inprogress',
                type: 'basic',
                link: '/rounds/inprogress'
            },
            {
                id: 'pages.rounds.completed',
                title: 'Completed',
                type: 'basic',
                link: '/rounds/completed'
            },
            {
                id: 'pages.rounds.approved',
                title: 'Approved',
                type: 'basic',
                link: '/rounds/approved'
            },
            {
                id: 'pages.rounds.invoiced',
                title: 'Invoiced',
                type: 'basic',
                link: '/rounds/invoiced'
            }
        ]
    },
    {
        id: 'pages.roles',
        title: 'User Roles',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/roles'
    },
    {
        id: 'pages.manage-users',
        title: 'Manage Users',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/manage-users'
    },
    {
        id: 'pages.facilities',
        title: 'Facilities',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/facilities'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
